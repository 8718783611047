import React from "react";
import { SvgIcon, SvgIconProps } from "../../core/svgicon";

export function IconArrowRight(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6893 11.25L11.4697 7.03033L12.5303 5.96967L18.5607 12L12.5303 18.0303L11.4697 16.9697L15.6893 12.75H5.5V11.25H15.6893Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
