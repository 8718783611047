import React from "react";
import { useTheme } from "styled-components";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconZebra(props: SvgIconProps) {
  const { colors } = useTheme();

  return (
    <SvgIcon width="839px" height="288px" viewBox="0 0 839 288">
      <g id="zebra" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-9" transform="translate(82.000000, 35.000000)" fill="#000000" fillRule="nonzero">
              <polygon id="path1017" points="144 112.2167 129 97 129 140 144 140"/>
              <path d="M82.7700935,66.0475483 L120,66.0475483 L104.781308,50.7057949 L104.695327,50.6191184 L85.1775701,50.6191184 C99.0205607,49.3189698 109.166355,36.9242199 107.876636,22.9692917 C106.672897,9.96780584 95.8392523,0 82.8560748,0 L82.8560748,50.7057949 L82.8560748,50.7057949 L41.0691589,8.66765726 C38.8336449,9.7944527 36.6841121,11.0946013 34.6205607,12.4814264 C32.2990654,13.9549282 30.1495327,15.6017831 28,17.3353145 L67.5514019,57.0331847 L67.5514019,144.229817 L98.0747664,175 L98.0747664,153.24418 L82.7700935,137.81575 L82.7700935,66.0475483 Z" id="path1029"/>
              <polygon id="path1033" points="99.0837989 66 99 140 113.916201 140 114 81.4382284"/>
              <polygon id="path1037" points="151 97 135.75463 82 114 82 129.159722 97"/>
              <path d="M53,129 L53,128.052856 L53,128.052856 L53,63.1304348 L17.4926108,28 C14.1855501,32.0468883 11.226601,36.4381927 8.78981938,41.001705 L37.5090312,69.4160273 L37.5090312,91.028133 L37.5090312,91.028133 L2.61083744,56.5865303 C0.957307061,62.7860188 0.0870279146,69.1577153 0,75.6155158 C0,75.6155158 51.2594417,127.191816 53,129" id="path1049"/>
              <polygon id="path1053" points="0 98 0 119.684665 98 218 98 196.315335"/>
              <path d="M175.035897,121 L159.517949,121 L159.517949,140.285721 L144,140.285721 C144.261538,154.23343 155.85641,165.253842 169.979487,164.995551 C183.666667,164.73726 194.738462,153.802945 195,140.285721 L175.035897,121 Z" id="path1065"/>
              <polygon id="path1069" points="300.616585 141.034109 289.068293 163 217 163 263.887805 72.9658915 222.383415 72.9658915 233.931707 51 306 51 259.112195 141.034109"/>
              <polygon id="path1073" points="342.193505 95.8 379 95.8 379 117.852713 342.193505 117.852713 342.193505 141.034109 379 141.034109 379 163 315 163 315 51 379 51 379 72.9658915 342.106901 72.9658915 342.106901 95.8"/>
              <path d="M430.286174,51.0868217 C444.524116,51.0868217 452.771704,52.5627907 458.848875,56.4697674 C466.575563,61.1581395 471.263666,70.7953488 471.263666,81.4744186 C471.263666,89.3751938 468.745981,95.8868217 463.710611,100.922481 C460.498392,104.308527 457.980707,105.784496 451.990354,107.434109 C458.848875,108.910078 461.88746,110.472868 465.446945,113.858915 C470.482315,118.547287 473,125.145736 473,133.306977 C473,143.725581 468.832797,152.928682 461.540193,157.790698 C455.983923,161.437209 449.299035,163 437.839228,163 L392,163 L392,51 L430.286174,51.0868217 Z M419.26045,97.4496124 L425.945338,97.4496124 C438.360129,97.4496124 443.569132,93.6294574 443.569132,84.3395349 C443.569132,75.2232558 438.360129,71.4031008 425.598071,71.4031008 L419.26045,71.4031008 L419.26045,97.4496124 Z M419.26045,143.63876 L429.157556,143.63876 C440.617363,143.63876 445.4791,139.731783 445.4791,130.702326 C445.4791,125.31938 443.482315,121.499225 439.401929,119.328682 C436.363344,117.67907 433.672026,117.331783 425.424437,117.331783 L419.26045,117.331783 L419.26045,143.63876 Z" id="path1085"/>
              <path d="M510.191753,162.913246 L483,162.913246 L483,51 L519.197938,51 C533.919588,51 544.657732,53.6893881 552.018557,59.415182 C559.725773,65.4879938 564.05567,75.5515105 564.05567,87.7838885 C564.05567,104.874516 556.868041,116.152595 542.406186,121.878389 L567,163 L534.525773,163 L510.105155,119.709527 L510.105155,162.913246 L510.191753,162.913246 Z M518.851546,104.353989 C529.936082,104.353989 536.604124,98.6281952 536.604124,88.9116964 C536.604124,79.7157242 530.109278,73.2958947 520.496907,73.2958947 L510.191753,73.2958947 L510.191753,104.353989 L518.851546,104.353989 Z" id="path1089"/>
              <path d="M645.576923,163 L675,163 L638.307692,51 L603.778846,51 L567,163 L595.557692,163 L602.480769,140.947287 L638.653846,140.947287 L645.576923,163 Z M609.403846,118.981395 L620.653846,83.124031 L631.817308,118.981395 L609.403846,118.981395 Z" id="path1093"/>
          </g>
      </g>
    </SvgIcon>
  );
}
