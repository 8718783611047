import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function IconWater() {
  return (
    <StaticImage
      src="../../../../images/icons/water-icon.svg" 
      alt="Agua"
      draggable={false}
    />
  );
}
