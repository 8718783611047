import React from "react";
import { Box, BoxProps } from "../../core/box";
import { Lottie, LottieProps } from "../../core/Lottie/Lottie";
import { Typography } from "../../core/Typography/Typography";
import { Caption, CaptionProps } from "../Caption/Caption";

const componentConfig = {
  withoutAnimation: {
    gridTemplate: `
      "caption"
      "title"
    `,
    gridTemplateColumns: "1fr",
  },
  animationOnly: {
    gridTemplate: `"animation"`,
    gridTemplateColumns: "1fr",
  },
  withAnimation: {
    gridTemplate: [
      `
      "caption"
      "animation"
      "title"
    `,
      `
      "caption animation"
      "title animation"
    `,
    ],
    gridTemplateColumns: ["1fr", "1fr 1fr"],
  },
};

export interface PromoPOSCardProps extends BoxProps {
  title?: string;
  subtitle?: string;
  animation?: LottieProps;
  scrollPlay?: boolean;
  caption?: CaptionProps;
  backgroundColor?: string;
  imageSvg?: any;
  image?: any;
}

export function PromoPOSCard(props: PromoPOSCardProps) {
  const { gridArea, title, subtitle, caption, animation, backgroundColor = "background.faint", imageSvg,  image, ...otherProps } = props;

  const hasAnimation = !!animation && animation.name;
  const isCardWithFullContent = (title || caption) && hasAnimation;

  let animationConfig;

  if (isCardWithFullContent) {
    animationConfig = componentConfig.withAnimation;
  } else if (hasAnimation) {
    animationConfig = componentConfig.animationOnly;
  } else {
    animationConfig = componentConfig.withoutAnimation;
  }

  return (
    <Box
      display="grid"
      gridArea={gridArea}
      gridGap="1.5rem"
      backgroundColor={backgroundColor}
      paddingSpacing={[3, 3, 6]}
      borderRadius={4}
      minHeight={["15rem", "29rem"]}
      {...animationConfig}
      {...otherProps}
    >
      {caption && (
        <Box gridArea="caption">
          <Caption {...caption} />
        </Box>
      )}

      

      {title && (
        <Box gridArea="title" display="grid" alignItems="self-end" flexGrow={1}>
          {image && (
        <Box {...({ marginBottom:2 })}>
          {/* <Lottie {...animation} /> */}
          {image}
        </Box>
      )}
          <Typography variant="h3" fontWeight={1} lineHeight={0}>
            {title}
          </Typography>
          {subtitle && (<Typography variant="h4" fontWeight={1} lineHeight={0}>
            {subtitle}
          </Typography>)}
        </Box>
      )}
      {hasAnimation && imageSvg && (
        <Box gridArea="animation" {...(isCardWithFullContent && { paddingXSpacing: [0, 3, 6] })}>
          {/* <Lottie {...animation} /> */}
          {imageSvg}
        </Box>
      )}
      
    </Box>
  );
}
