import React from "react";
import { useTheme } from "styled-components";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconCisco(props: SvgIconProps) {
  const { colors } = useTheme();

  return (
    
<SvgIcon width="839px" height="288px" viewBox="0 0 839 288">
    <g id="cisco" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="partner-logo" transform="translate(263.000000, 19.000000)" fillRule="nonzero">
            <path d="M35,191 L54.4548194,191 C61.4320714,191 66.7201833,192.803197 70.3191552,196.409591 C73.5283548,199.857184 75.209192,204.459106 74.9791446,209.16806 C75.2301911,214.459103 72.809214,219.521996 68.5366455,222.641003 C64.3357397,225.526362 59.3139625,226.967952 54.2256396,226.74923 L39.5072029,226.74923 L39.5072029,249 L35,249 L35,191 Z M54.6330704,194.878575 L39.5072029,194.878575 L39.5072029,222.947206 L54.3020329,222.947206 C58.805335,223.240203 63.2398677,221.725313 66.6268138,218.736912 C69.1075907,216.209095 70.4268391,212.760882 70.2682263,209.219094 C70.5047251,205.006643 68.7731826,200.923469 65.5827724,198.17026 C62.4317986,195.818731 58.5554553,194.65343 54.6330704,194.878575 L54.6330704,194.878575 Z" id="Shape" fill="#00BCEA"/>
            <path d="M84.8275642,217.228895 L80.2200657,217.228895 C80.4773456,213.409607 82.5107677,209.944747 85.6930611,207.903156 C88.9182177,205.868591 92.6695782,204.866827 96.4608614,205.02772 C100.811375,204.7705 105.079646,206.311764 108.297805,209.302017 C110.800376,211.888606 112.136888,215.415454 111.988895,219.042234 L111.988895,248.832789 L108.45054,248.832789 L107.737778,241.708961 C104.73668,247.097443 99.0005705,250.296922 92.9225062,249.972602 C89.1247392,250.204829 85.3829454,248.954093 82.4601755,246.47545 C80.1217814,244.100432 78.8702985,240.835681 79.0106548,237.476662 C79.1510111,234.117643 80.6703188,230.97279 83.1983935,228.808355 C86.7091404,226.33145 90.7662017,224.774316 95.0098812,224.275009 L107.737778,222.150813 L107.737778,218.472327 C107.890441,215.507641 106.594838,212.657447 104.27579,210.856307 C101.950632,209.219928 99.1562967,208.41788 96.3335825,208.576682 C93.3030229,208.3867 90.305558,209.305477 87.8822593,211.167165 C86.0970353,212.681488 84.9942266,214.869899 84.8275642,217.228895 L84.8275642,217.228895 Z M94.1189285,246.216401 C97.7519555,246.26407 101.256294,244.848781 103.868497,242.278867 C106.339681,240.065394 107.750355,236.873167 107.737861,233.523034 L107.737861,225.751585 L96.4863172,227.720352 C92.6647844,228.047635 89.0117311,229.466389 85.9476191,231.813315 C84.3519491,233.325326 83.4946968,235.477814 83.6056861,237.693712 C83.4851994,240.382896 84.7734435,242.935835 86.9913066,244.403063 C89.1403717,245.707354 91.6195502,246.338081 94.1189285,246.216401 Z" id="Shape" fill="#00BCEA"/>
            <path d="M125.271357,248 L121,248 L121,206.22132 L124.492462,206.22132 L125.120603,214.25666 C127.281407,208.103014 131.150754,205.000762 136.703518,205.000762 C138.161538,204.985183 139.612445,205.208446 141,205.661898 L141,209.908422 C139.531452,209.464132 138.009896,209.224595 136.477387,209.19643 C133.073659,209.1972 129.92632,211.027025 128.211055,214.002377 C126.130383,217.342829 125.104939,221.245653 125.271357,225.190824 L125.271357,248 Z" id="Path" fill="#00BCEA"/>
            <path d="M167.221411,210.17939 L157.902676,210.17939 L157.902676,240.349405 C157.902676,244.334124 159.654501,246.326484 163.13382,246.326484 C164.775247,246.333555 166.410716,246.11615 168,245.679614 L168,249.172712 C166.358006,249.697027 164.653574,249.967172 162.939173,249.97483 C160.300459,250.178584 157.711047,249.137659 155.858881,247.128602 C154.397513,245.152595 153.662382,242.678498 153.790754,240.168282 L153.790754,210.17939 L148,210.17939 L148,206.34992 L153.766423,206.34992 L153.985401,196 L157.902676,196 L157.902676,206.34992 L167.221411,206.34992 L167.221411,210.17939 Z" id="Path" fill="#00BCEA"/>
            <path d="M178.390121,248 L174,248 L174,206.244998 L177.641218,206.244998 L178.131878,213.386298 C179.566486,210.634006 181.806218,208.3679 184.562114,206.880345 C186.947419,205.63711 189.61032,204.999434 192.309385,205.025132 C196.514235,204.775244 200.610472,206.3989 203.465456,209.447147 C205.927911,212.261209 207.186709,215.904722 206.977553,219.612698 L206.977553,248 L202.535784,248 L202.535784,219.739767 C202.764713,216.555914 201.544141,213.436581 199.204457,211.226118 C196.97102,209.445186 194.147232,208.540001 191.276416,208.68473 C187.728197,208.638792 184.335064,210.113612 181.97969,212.725537 C179.602235,215.219666 178.313455,218.531846 178.390121,221.950775 L178.390121,248 Z" id="Path" fill="#00BCEA"/>
            <path d="M248.368635,237.222748 L253.196167,237.222748 C252.244161,241.203743 249.800243,244.677613 246.359338,246.940853 C237.941755,252.062332 227.052135,250.638604 220.26457,243.529178 C216.644984,239.008296 214.792077,233.346567 215.045616,227.582182 C214.641442,221.165249 216.936779,214.868803 221.386645,210.18781 C224.894129,206.756477 229.662383,204.889757 234.590597,205.018605 C240.207047,204.760818 245.605953,207.194096 249.099288,211.557649 C252.60243,216.514688 254.312711,222.498254 253.952915,228.538485 L219.429537,228.538485 C219.241044,233.713972 221.202794,238.739745 224.857249,242.443645 C227.730265,244.959657 231.461743,246.299424 235.295156,246.191318 C238.442222,246.283859 241.52667,245.308068 244.036903,243.425794 C246.112724,241.869496 247.630774,239.695647 248.368635,237.222748 L248.368635,237.222748 Z M234.434029,208.792124 C230.34162,208.673207 226.419279,210.415722 223.787363,213.521946 C221.16993,216.81171 219.671774,220.838078 219.507822,225.023426 L249.177572,225.023426 C249.271019,220.272803 247.438217,215.683274 244.089093,212.281337 C241.447926,209.918927 237.99164,208.651367 234.434029,208.740432 L234.434029,208.792124 Z" id="Shape" fill="#00BCEA"/>
            <path d="M266.296482,248 L262,248 L262,206.221491 L265.492462,206.221491 L266.120603,214.256798 C268.306533,208.103177 272.150754,205.000938 277.728643,205.000938 C279.178562,204.983592 280.621483,205.206932 282,205.662071 L282,209.908578 C280.531871,209.462415 279.010059,209.222838 277.477387,209.196589 C274.075025,209.202531 270.930047,211.030976 269.211055,214.002516 C267.150892,217.350935 266.135239,221.249803 266.296482,225.190917 L266.296482,248 Z" id="Path" fill="#00BCEA"/>
            <rect id="Rectangle" fill="#00BCEB" x="89" y="110" width="14" height="54"/>
            <path d="M213,125.996364 C209.413025,124.033574 205.383531,123.01695 201.292954,123.042727 C193.178669,123.042727 186.600742,129.608268 186.600742,137.707273 C186.600742,145.806278 193.178669,152.371818 201.292954,152.371818 C205.387271,152.325106 209.407408,151.275255 213,149.314545 L213,164.056818 C208.893344,165.310281 204.626747,165.964774 200.332509,166 C184.757726,166 171,155.247727 171,137.5 C171,121.099545 183.407911,109 200.332509,109 C204.630509,109.012764 208.901664,109.676691 213,110.969091 L213,125.996364 Z" id="Path" fill="#00BCEB"/>
            <path d="M69,125.996364 C65.4330748,124.024776 61.4154508,123.007261 57.3376623,123.042727 C49.2183593,123.042727 42.6363636,129.608268 42.6363636,137.707273 C42.6363636,145.806278 49.2183593,152.371818 57.3376623,152.371818 C61.4186118,152.329398 65.4254419,151.279011 69,149.314545 L69,164.056818 C64.8932073,165.32074 60.6224257,165.97547 56.3246753,166 C40.7402597,166 27,155.247727 27,137.5 C27,121.099545 39.4155844,109 56.3246753,109 C60.6257785,109.007111 64.9004284,109.67117 69,110.969091 L69,125.996364 Z" id="Path" fill="#00BCEB"/>
            <path d="M284.994877,136.462982 C285.215522,148.236512 278.291625,158.973997 267.474587,163.633208 C256.657549,168.29242 244.096291,165.947757 235.689468,157.700251 C227.282646,149.452745 224.700364,136.940853 229.15524,126.040048 C233.610116,115.139242 244.21714,108.015086 255.995349,108.01302 C263.661057,107.77995 271.08994,110.684402 276.56395,116.054675 C282.037961,121.424949 285.083009,128.795937 284.994877,136.462982 L284.994877,136.462982 Z M255.995349,122.031092 C250.147666,121.989181 244.852433,125.478701 242.585515,130.868075 C240.318596,136.257449 241.527929,142.481754 245.648071,146.630724 C249.768213,150.779694 255.985037,152.033506 261.391775,149.805921 C266.798514,147.578336 270.326812,142.309518 270.326962,136.462982 C270.397129,132.632325 268.913135,128.936446 266.213162,126.217581 C263.513188,123.498716 259.827261,121.988501 255.995349,122.031092 Z" id="Shape" fill="#00BCEB"/>
            <path d="M154.338144,122.54918 C150.872244,121.5623 147.304427,120.996005 143.708591,120.862022 C138.241237,120.862022 135.291409,122.730874 135.291409,125.378415 C135.291409,128.726776 139.283849,129.894809 141.521649,130.569672 L145.285223,131.789617 C154.109278,134.644809 158,140.822404 158,147.545082 C158,161.353825 146.098969,166 135.672852,166 C130.746845,165.961829 125.834899,165.457854 121,164.494536 L121,151.853825 C125.224682,153.119477 129.592997,153.817528 133.994502,153.930328 C140.758763,153.930328 143.886598,151.905738 143.886598,148.739071 C143.886598,145.572404 141.191065,144.352459 137.808935,143.262295 L134.909966,142.301913 C127.2811,139.862022 121,135.319672 121,126.209016 C121,115.826503 128.628866,109 141.089347,109 C145.564375,109.064118 150.017739,109.647558 154.363574,110.739071 L154.338144,122.54918 Z" id="Path" fill="#00BCEB"/>
            <path d="M14,51.8455882 C14,48.0648742 10.8659932,45 7,45 C3.13400675,45 0,48.0648742 0,51.8455882 L0,66.1544118 C0,69.9351258 3.13400675,73 7,73 C10.8659932,73 14,69.9351258 14,66.1544118 L14,51.8455882 Z" id="Path" fill="#00BCEB"/>
            <path d="M50.9867932,32.4517941 C50.7600767,28.8263359 47.6993739,26 44,26 C40.3006261,26 37.2399233,28.8263359 37.0132068,32.4517941 L37.0132068,65.7057798 C36.8536592,68.2571294 38.1493429,70.6846493 40.3768471,72.0077068 C42.6043513,73.3307644 45.3956487,73.3307644 47.6231529,72.0077068 C49.8506571,70.6846493 51.1463408,68.2571294 50.9867932,65.7057798 L50.9867932,32.4517941 Z" id="Path" fill="#00BCEB"/>
            <path d="M89,7.82254697 C89,4.05455832 85.8659932,1 82,1 C78.1340068,1 75,4.05455832 75,7.82254697 L75,80.177453 C75,83.9454417 78.1340068,87 82,87 C85.8659932,87 89,83.9454417 89,80.177453 L89,7.82254697 Z" id="Path" fill="#00BCEB"/>
            <path d="M125.986793,32.4517941 C125.760077,28.8263359 122.699374,26 119,26 C115.300626,26 112.239923,28.8263359 112.013207,32.4517941 L112.013207,65.7057798 C111.853659,68.2571294 113.149343,70.6846493 115.376847,72.0077068 C117.604351,73.3307644 120.395649,73.3307644 122.623153,72.0077068 C124.850657,70.6846493 126.146341,68.2571294 125.986793,65.7057798 L125.986793,32.4517941 Z" id="Path" fill="#00BCEB"/>
            <path d="M163,51.8324128 C163,49.3914269 161.665808,47.1358628 159.5,45.9153698 C157.334193,44.6948767 154.665807,44.6948767 152.5,45.9153698 C150.334192,47.1358628 149,49.3914269 149,51.8324128 L149,66.1675876 C149,69.9410248 152.134007,73 156,73 C159.865993,73 163,69.9410248 163,66.1675876 L163,51.8324128 Z" id="Path" fill="#00BCEB"/>
            <path d="M201,32.8547056 C201,29.0689562 197.865993,26 194,26 C190.134007,26 187,29.0689562 187,32.8547056 L187,66.1452944 C187,69.9310438 190.134007,73 194,73 C197.865993,73 201,69.9310438 201,66.1452944 L201,32.8547056 Z" id="Path" fill="#00BCEB"/>
            <path d="M238.986743,7.23223981 C239.146603,4.70267427 237.851002,2.29574429 235.623384,0.98389041 C233.395766,-0.32796347 230.604234,-0.32796347 228.376616,0.98389041 C226.148998,2.29574429 224.853397,4.70267427 225.013257,7.23223981 L225.013257,79.7677602 C224.853397,82.2973257 226.148998,84.7042557 228.376616,86.0161096 C230.604234,87.3279635 233.395766,87.3279635 235.623384,86.0161096 C237.851002,84.7042557 239.146603,82.2973257 238.986743,79.7677602 L238.986743,7.23223981 Z" id="Path" fill="#00BCEB"/>
            <path d="M276,33.3075643 C275.773263,29.763264 272.706652,27 269,27 C265.293348,27 262.226737,29.763264 262,33.3075643 L262,65.6924357 C262.226737,69.236736 265.293348,72 269,72 C272.706652,72 275.773263,69.236736 276,65.6924357 L276,33.3075643 Z" id="Path" fill="#00BCEB"/>
            <path d="M313,51.8324125 C313,48.0589753 309.865993,45 306,45 C302.134007,45 299,48.0589753 299,51.8324125 L299,66.1675875 C299,69.9410247 302.134007,73 306,73 C309.865993,73 313,69.9410247 313,66.1675875 L313,51.8324125 Z" id="Path" fill="#00BCEB"/>
        </g>
    </g>
</SvgIcon>
  );
}
