import React from "react";
import { Box } from "../../../core/box";
import { Card, CardProps } from "../../../core/Card/Card";
import { LinkStyleButton, LinkStyleButtonProps } from "../../../core/LinkStyleButton/LinkStyleButton";
import { Typography } from "../../../core/Typography/Typography";
import { Highlight, highlightText } from "../../../utils/highlightText";

export interface ConnectorCardProps extends CardProps {
  text: string;
  highlights: Highlight[];
  // linkProps: LinkStyleButtonProps;
}

export function ConnectorCard(props: ConnectorCardProps) {
  const { text, highlights, ...otherProps } = props;
  const textWithHighlight = highlightText(text, highlights, (highlight, accent) => (
    <Box as="span" color={accent}>
      {highlight}
    </Box>
  ));

  return (
    <Card display="flex" flexDirection="column" minHeight={["auto", "auto", "31rem"]} {...otherProps}>
      <Typography as="p" variant="h4" fontSize={5} color="text.muted">
        {textWithHighlight}
      </Typography>
      <Box marginBottomSpacing={6.625} flexGrow={1} />
    </Card>
  );
}
