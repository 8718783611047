import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconSiemens(props: SvgIconProps) {
  return (
    <SvgIcon width="140px" height="54px" viewBox="0 0 140 54" fill="none" {...props}>
    <g id="siemens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
     <g transform="translate(8.000000, 17.000000)" fill="#FFFFFF" fillRule="nonzero" id="Path">
         <path d="M123.538369,0.69786055 L123.538369,4.42052858 C121.609753,3.69245956 119.89266,3.32433764 118.389836,3.32433764 C117.499676,3.32433764 116.79083,3.48917502 116.257871,3.80786488 C115.724848,4.12930097 115.452844,4.52494901 115.452844,4.9919989 C115.452844,5.61290128 116.054522,6.16508416 117.266119,6.65966017 L120.766279,8.36302237 C123.596104,9.71199377 125,11.5032352 125,13.7533517 C125,15.6242975 124.255454,17.1161365 122.75263,18.2205661 C121.260791,19.3360445 119.25247,19.8882274 116.752383,19.8882274 C115.598457,19.8882274 114.562747,19.8387953 113.642379,19.7343749 C112.72201,19.6355108 111.664266,19.4321624 110.491117,19.1409347 L110.491117,15.2451907 C112.642306,15.9732597 114.601194,16.3413816 116.364974,16.3413816 C118.469476,16.3413816 119.516235,15.7314641 119.516235,14.5006443 C119.516235,13.8879806 119.087632,13.3934684 118.21676,13.0060591 L114.32919,11.3493827 C112.895086,10.7010181 111.829104,9.91527831 111.131243,8.98392475 C110.444431,8.04433251 110.095469,6.96736514 110.095469,5.73654528 C110.095469,4.01389564 110.820792,2.62647709 112.254896,1.57147952 C113.700049,0.524720626 115.617745,0 118.002426,0 C118.777181,0 119.670088,0.068655632 120.659176,0.195045861 C121.656439,0.329674765 122.615255,0.494512148 123.538369,0.69786055 L123.538369,0.69786055 Z"></path>
         <path d="M13.4346618,0.69786055 L13.4346618,4.42052858 C11.5059814,3.69245956 9.79163431,3.32433764 8.28881042,3.32433764 C7.39590401,3.32433764 6.68986865,3.48917502 6.15684548,3.80786488 C5.62388618,4.12930097 5.35188215,4.52494901 5.35188215,4.9919989 C5.35188215,5.61290128 5.96179962,6.16508416 7.1733959,6.65966017 L10.6735558,8.36302237 C13.4923325,9.71199377 14.8990385,11.5032352 14.8990385,13.7533517 C14.8990385,15.6242975 14.1517459,17.1161365 12.659907,18.2205661 C11.1570831,19.3360445 9.15974703,19.8882274 6.6514215,19.8882274 C5.49749595,19.8882274 4.45897574,19.8387953 3.53860707,19.7343749 C2.61823841,19.6355108 1.57147952,19.4321624 0.390155587,19.1409347 L0.390155587,15.2451907 C2.54958278,15.9732597 4.50847166,16.3413816 6.26126591,16.3413816 C8.3657686,16.3413816 9.41252749,15.7314641 9.41252749,14.5006443 C9.41252749,13.8879806 8.98667097,13.3934684 8.12397304,13.0060591 L4.23646762,11.3493827 C2.79131447,10.7010181 1.725332,9.91527831 1.03852022,8.98392475 C0.340659666,8.04433251 0,6.96736514 0,5.73654528 C0,4.01389564 0.719830352,2.62647709 2.16217342,1.57147952 C3.59634167,0.524720626 5.51671953,0 7.90146493,0 C8.68445846,0 9.56637997,0.068655632 10.5663892,0.195045861 C11.5554773,0.329674765 12.5142931,0.494512148 13.4346618,0.69786055 Z"></path>
         <polygon points="17.4458112 0.348898342 23.1466556 0.348898342 23.1466556 19.539329 17.4458112 19.539329"></polygon>
         <polygon points="41.5128355 0.348898342 41.5128355 3.89580796 33.0426465 3.89580796 33.0426465 8.15143529 40.3891823 8.15143529 40.3891823 11.3493827 33.0426465 11.3493827 33.0426465 15.816661 41.6859115 15.816661 41.6859115 19.539329 27.5561993 19.539329 27.5561993 0.348898342"></polygon>
         <polygon points="68.1265685 0.348898342 68.1265685 19.539329 62.8046394 19.539329 62.8046394 6.88216829 57.2827468 19.7151513 53.9969201 19.7151513 48.6862314 6.88216829 48.6862314 19.539329 44.8371731 19.539329 44.8371731 0.348898342 51.7962357 0.348898342 56.5464391 12.0966753 61.5164682 0.348898342"></polygon>
         <polygon points="86.4847652 0.348898342 86.4847652 3.89580796 78.0608788 3.89580796 78.0608788 8.15143529 85.4073508 8.15143529 85.4073508 11.3493827 78.0608788 11.3493827 78.0608788 15.816661 86.6578413 15.816661 86.6578413 19.539329 72.5281929 19.539329 72.5281929 0.348898342"></polygon>
         <polygon points="106.488142 0.348898342 106.488142 19.539329 100.441338 19.539329 93.6556065 7.23106663 93.6556065 19.539329 89.8089751 19.539329 89.8089751 0.348898342 96.0320493 0.348898342 102.64215 12.4456375 102.64215 0.348898342"></polygon>
       </g>
   </g>
 </SvgIcon>
  );
}
