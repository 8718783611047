import React from "react";
import { Box, BoxProps } from "../../../core/box";
import {
  ServicesElementsCard,
  ServicesElementsCardProps,
} from "../../../ui/ServicesElementsCard/ServicesElementsCard";
import { SectionHeader, SectionHeaderProps } from "../../../ui/SectionHeader/SectionHeader";
import { Section, SectionProps } from "../../Section/Section";
import { ConnectorCard, ConnectorCardProps } from "./ConnectorCard";
import { BrandProviders, BrandProvidersProps } from "./BrandProviders";
import { QuoteBlock, QuoteBlockProps } from "../../../ui/QuoteBlock/QuoteBlock";

export interface DeploymentSubSectionProps extends SectionProps, Pick<BoxProps, "gridTemplate"> {
  headerSectionProps: SectionHeaderProps;
  servicesElementsCardProps: ServicesElementsCardProps;
  connectorCardProps: ConnectorCardProps;
  brandProvidersProps: BrandProvidersProps;
  subtitleQuoteProps: any;
}

export function DeploymentSubSection(props: DeploymentSubSectionProps) {
  const {
    theme,
    gridTemplate,
    headerSectionProps,
    servicesElementsCardProps,
    connectorCardProps,
    brandProvidersProps,
    subtitleQuoteProps,
    ...otherProps
  } = props;

  return (
    <Section theme={theme} {...otherProps}>
      <Box display="grid" gridTemplate={gridTemplate}>
        <SectionHeader width={["100%", "90%", "80%"]} {...headerSectionProps} paddingLeftSpacing={[0, 0, 6]} />
        <QuoteBlock {...subtitleQuoteProps} />
        <ServicesElementsCard {...servicesElementsCardProps} />
        <ConnectorCard {...connectorCardProps} />
        <BrandProviders {...brandProvidersProps} />
      </Box>
    </Section>
  );
}
