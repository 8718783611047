import React from "react";
import type { BlogProjectsSectionProps } from "../../components/rebrand/sections/BlogProjectsSection/BlogProjectsSection";
import { links } from "../site";

export const blogProjectsData: BlogProjectsSectionProps = {
  sectionProps: {
    theme: "light",
  },
  headerProps: {
    /* grid props */
    gridColumn: ["1 / span 12", "1 / span 12", "1 / span 9"],
    gridRow: "1",

    /* content props */
    variant: "medium",
    title: "Descubre nuestros proyectos de éxito",
  },
  caseStudiesCardsProps: [
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: "2",

      /* content props */
      variant: "quote",
      caption: "Vending Machine IoT",
      quote: "Tecnología para ayudar a salvar el planeta, ",
      highlights: [
        { keyword: "reutilización de plástico que descontamina nuestros oceanos.", accent: "text.loud" },
      ],
      source: "Proyecto en desarrollo",
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / span 6"],
      gridAutoRows: ["3", "2"],

      /* content props */
      variant: "quote",
      caption: "Placas de desarrollo",
      quote: "Implementación de sensores distribuídos mediante el uso de ",
      highlights: [{ keyword: "placas de desarrollo a bajo costo.", accent: "text.loud" }],
      source: "Proyecto en desarrollo",
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 12"],
      gridAutoRows: ["3", "2"],

      /* content props */
      variant: "quote",
      caption: "Software SaaS",
      quote: "Web App que permite administrar trabajos en terreno y concentrar datos de sensores para ",
      highlights: [{ keyword: "lograr eficiencia en procesos que requieran mantención y toma de decisiones basadas en datos", accent: "text.loud" }],
      source: "Próximamente...",
    },
  ],
};
