import React from "react";
import { IconAdminAPI } from "../../components/rebrand/icons/IconAdminAPI";
import { IconServiceAccounts } from "../../components/rebrand/icons/IconServiceAccounts";
import { IconTerraformProvider } from "../../components/rebrand/icons/IconTerraformProvider";
import type { PromoMeterIOTSectionProps } from "../../components/rebrand/sections/PromoMeterIOTSection/PromoMeterIOTSection";

import {RowSensors} from "../../components/rebrand/graphics/home/RowSensors";
import { DashboardRemote } from "../../components/rebrand/graphics/home/DashboardRemote";
import { ImagePCB } from "../../components/rebrand/graphics/home/ImagePCB";
import { ImageDashboard } from "../../components/rebrand/graphics/home/ImageDashboard";
import { ImageDashboardRemote } from "../../components/rebrand/graphics/home/ImageDashboardRemote";
import { ImageSensorsMeter } from "../../components/rebrand/graphics/home/ImageSensorsMeter";

export const promoMeterIOTData: PromoMeterIOTSectionProps = {
  sectionProps: {
    theme: "dark",
  },
  headerProps: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: "1",

    /* content props */
    title: "Conoce como capturar y tomar datos en tiempo real, obteniendo KPI's e indicadores a medida.",
    captionProps: {
      // Icon: <IconAdminAPI />,
      text: "Monitorización en Tiempo Real",
      accent: "text.teal",
    },
  },
  textCardProps: {
    /* appearance props */
    display: ["none", "block"],

    /* grid props */
    gridColumn: ["1 / span 12", "1 / span 12"],
    gridRow: "2",

    /* content props */
    children: "Lectura de variables análogas y/o digitales de forma simple y consistente, permiten acortar tiempos de desarrollo e implementación.",

    // captionProps: {
    //   Icon: <IconServiceAccounts color="icon.yellow" />,
    //   text: "Dashboard e indicadores",
    // },

    ImageSvg : <ImageSensorsMeter />

  },
  codeProps: {
    cardProps: {
      /* appearance props */
      display: ["none", "block"],
      isOutlined: true,

      /* grid props */
      gridColumn: "1 / span 12",
      gridRow: "3",

      /* content props */
      children: "code",

      backgroundColor: "#F7F8F9",

      
    },
    ImageSvg : <ImageDashboardRemote />
  },
  serviceAccountsProps: {
    cardProps: {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: ["3", "4"],

      /* content props */
      children: "Plataforma web con datos concentrados y autoadministrables. ",
    },
    captionProps: {
      // Icon: <IconServiceAccounts color="icon.yellow" />,
      text: "Dashboard e indicadores",
    },
    image : <ImageDashboard />
  },
  terraformProviderProps: {
    cardProps: {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / col-end 12"],
      gridRow: "4",

      /* content props */
      children: "Envia datos mediante RS485, Modbus, Señales Digitales y Analogas, y mucho más...",
    },
    captionProps: {
      // Icon: <IconTerraformProvider color="icon.blue" />,
      text: "Comunicación y control con sensores",
    },
    image: <ImagePCB />
  },
  quoteBlockProps: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: "5",

    /* content props */
    text: "La plataforma web de Polarycs nos ha permitido reducir los tiempos de implementación de nuevos puntos remotos de recolección de datos para nuestros clientes.",
    color: "text.muted",
    authorName: "Nombre y Apellido",
    authorPosition: "Empresa cliente",
    highlights: [
      {
        keyword: "reducir los tiempos de implementación de nuevos puntos remotos de recolección",
        accent: "text.loud",
      },
    ],
  },
};
