import React from "react";
import { Box, BoxProps } from "../../core/box";
import { Grid, GridItemProps } from "../../core/Grid/Grid";
// import { Lottie, LottieProps } from "../../core/Lottie/Lottie";
import { QuoteBlock, QuoteBlockProps } from "../../ui/QuoteBlock/QuoteBlock";
import { SectionHeader, SectionHeaderProps } from "../../ui/SectionHeader/SectionHeader";
import { PromoPOSCard, PromoPOSCardProps } from "../../ui/PromoPOSSection/PromoPOSSection";
import { Section, SectionProps } from "../Section/Section";
import { PromoReseller, PromoResellerProps } from "./PromoReseller";

export interface PromoPOSSectionProps extends SectionProps, Pick<BoxProps, "gridTemplate"> {
  headerProps: SectionHeaderProps;
  subtitleQuoteProps: QuoteBlockProps;
  networkRequestAnimationProps: GridItemProps & LottieProps;
  networkRequestQuoteProps: QuoteBlockProps;
  cards: PromoPOSCardProps[];
  footerQuote: QuoteBlockProps;
  promoReseller: PromoResellerProps
}

export function PromoPOSSection(props: PromoPOSSectionProps) {
  const {
    theme,
    gridTemplate,
    headerProps,
    subtitleQuoteProps,
    networkRequestAnimationProps,
    networkRequestQuoteProps,
    cards,
    promoReseller,
    footerQuote,
  } = props;

  return (
    <Section theme={theme}>
      <Grid>
        <SectionHeader paddingLeftSpacing={[0, 0, 6]} {...headerProps} />
        <QuoteBlock {...subtitleQuoteProps} />
        {/* <Lottie marginTopSpacing={[5, 8.5]} paddingBottomSpacing={3.6} {...networkRequestAnimationProps} /> */}
        {/* <QuoteBlock marginTopSpacing={[3, 4]} marginBottomSpacing={[3, 14.25]} {...networkRequestQuoteProps} /> */}
        {cards.map((card, idx) => (
          <PromoPOSCard key={idx} {...card} />
        ))}
        
        {/* <QuoteBlock {...footerQuote} marginTopSpacing={[3, 6]} />  */}
      </Grid>

      {/* <Box display="grid" gridTemplate={gridTemplate}>
        <PromoReseller {...promoReseller}/>
      </Box> */}
    </Section>
  );
}
