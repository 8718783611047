import React from "react";
import { useTheme } from "styled-components";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconStar(props: SvgIconProps) {
  const { colors } = useTheme();

  return (

<SvgIcon width="839px" height="288px" viewBox="0 0 839 288">
    <g id="star" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-7" transform="translate(156.000000, 52.000000)" fill="#003F98" fillRule="nonzero">
            <path d="M306,181 L285.762518,181 L285.762518,110.425039 C285.762518,103.540381 279.318589,89.7094564 263.995128,89.7094564 C252.478073,89.7094564 243.610787,100.366805 243.610787,111.394509 C243.610787,121.150298 252.404626,133.276992 268.101375,133.276992 C273.517224,133.276992 277.482718,130.918338 277.482718,130.918338 L277.482718,150.670751 C277.482718,150.670751 272.642109,152.887283 265.531159,152.887283 C250.942042,152.887283 243.543464,148.454043 234.731254,140.309721 C227.387752,133.523954 223,119.155873 223,111.159799 C223,103.176151 227.11237,89.4192629 233.856157,82.7631895 C240.599943,76.106941 249.993516,69 264.943672,69 C279.899952,69 289.575013,77.5764615 295.890435,83.9425164 C305.859248,94.0007501 306,115.759839 306,115.759839 L306,181 Z" id="path18156"/>
            <path d="M375,94.532423 L375,43.7322468 L395.438331,43.7322468 L395.438331,52.2686262 C395.438331,52.2686262 403.278353,46.2335292 414.238326,42.5584315 C425.185934,38.8709651 439.105212,37.3961179 439.105212,37.3961179 L439.105212,48.5935286 C439.105212,48.5935286 444.881742,47.5604735 450.355546,47.1179845 C455.835532,46.6754955 462.785907,46.8166042 462.785907,46.8166042 L462.785907,61.2589923 C462.785907,61.2589923 459.239679,61.2589923 455.532807,61.3983589 C451.838298,61.5377255 446.358312,62.4314139 446.358312,62.4314139 L462.934187,67.1390439 L463.0883,82.7552473 L472.855899,70.0889125 L488.690406,75.3987805 L479.373813,61.9889249 L526,5 L526,27.2413438 L497.13588,63.0214573 L522.744151,100.135135 L477.884879,86.5839966 L448.433836,122.665316 L448.279724,77.4514766 L439.10523,74.5077053 L439.10523,83.0440846 C439.10523,83.0440846 420.867447,84.064945 409.196999,96.5952231 C394.40042,112.500264 395.580447,129.437837 395.580447,129.437837 L395.580447,181 L375,181 L375,129.726674 C375,121.632784 375.952624,104.553928 392.769404,86.4425395 C405.780506,72.4058825 425.488677,66.5490005 425.488677,66.5490005 L452.128695,74.7965426 L451.979837,112.80147 L476.704642,82.1652039 L514.60734,94.3890496 L492.841858,62.7202512 L522.601825,26.2088114 L522.601825,14.8638463 L483.963924,62.290131 L496.690828,81.1326715 L474.190143,74.3664223 L458.942575,92.9079309 L458.793717,69.5051406 C458.793717,69.5051406 439.993722,63.3224892 431.850974,61.7000876 C431.640996,61.6478251 441.173749,59.0512509 447.543383,58.4613817 C453.900653,57.8708157 458.793717,57.8708157 458.793717,57.8708157 L458.793717,50.213317 C458.793717,50.213317 441.760671,50.1087921 423.411682,56.3957943 C403.128935,63.3467041 388.937824,76.1173896 383.593754,81.5809093 C377.817224,87.4623547 375,94.5298099 375,94.5298099" id="path18160"/>
            <polygon id="path18162" points="345 43 365 43 365 181 345 181"/>
            <path d="M316.713098,181.632542 L337,181.632542 L337,108.90072 C337,91.8704789 330.039897,74.8342799 313.900623,58.6880461 C297.755181,42.5481203 275.094669,39 263.986956,39 C252.873041,39 234.063462,43.4443934 223.104088,52.0244348 C212.144713,60.6228746 192,77.2080458 192,112.900044 C192,142.973293 212.20653,165.263773 227.987293,174.375446 C240.393082,181.533716 251.686245,184 266.057669,184 C276.133127,184 277.758789,182.515849 277.758789,182.515849 L277.758789,161.782939 C277.758789,161.782939 273.462822,163.414279 265.90933,163.414279 C258.362006,163.414279 247.989853,162.820268 236.282548,155.118802 C224.587596,147.416635 213.183171,132.599479 213.183171,111.427632 C213.183171,90.2373869 225.032647,75.8716088 236.282548,68.1692668 C247.544802,60.4609672 259.684788,59.5832679 265.315923,59.5832679 C270.947042,59.5832679 287.383011,61.4995227 300.270944,74.3941161 C313.158877,87.2764438 316.713098,101.055045 316.713098,122.387572 L316.713098,181.632542 Z" id="path18166"/>
            <path d="M141,122.383062 C141,137.536539 141.930064,142.312529 145.698513,146.152064 C149.94116,150.475475 152.256982,153 167.112321,153 L191,153 L191,133.672213 C191,133.672213 181.530023,133.531551 171.482624,133.531551 C161.726959,133.531551 161.429125,130.696868 161.429125,123.569895 L161.429125,90.8750326 L191,90.8750326 L191,72 L141,72 L141,122.383062 Z" id="path18170"/>
            <path d="M110,72 L130.719328,72 L130.719328,127.605102 C130.719328,142.315148 134.900531,148.350226 138.627523,152.316412 C142.34829,156.28869 149.05562,162.028958 166.203515,162.028958 L192,162.028958 L192,181 C192,181 170.384701,180.711107 156.522038,180.711107 C147.568555,180.711107 131.91395,175.553846 121.628962,163.489782 C112.48258,152.770287 110,139.662554 110,132.160213 L110,72 Z" id="path18174"/>
            <polygon id="path18178" points="141 0 161.339803 0 161.339803 43.628606 191 43.628606 191 62 141 62"/>
            <polygon id="path18180" points="111 0 131 0 131 62 111 62"/>
            <path d="M101.332636,90.8592156 L101.332636,72 L41.6221136,72 C32.5081115,72 30.5196019,76.1491947 29.8567654,78.337576 C27.51229,86.138313 40.7444728,94.9777426 51.4787362,103.07958 C62.2191408,111.187691 73.020923,119.350864 77.9492429,124.293235 C83.2457938,129.591945 90.4572116,138.640472 86.1855869,149.330242 C81.484371,161.120388 70.1547726,161.851881 60.8873265,161.851881 L4,161.851881 L4,181 L59.5677947,181 C78.9741865,181 91.0341229,177.016689 100.602281,162.140784 C109.078012,148.95526 106.064559,133.046587 101.77451,125.614907 C95.7476038,115.15863 86.7747633,107.198107 76.6296764,100.430139 C68.6879206,95.1190578 66.7239587,94.5229552 67.3622476,92.1808871 C67.8041386,90.5586377 73.6653358,90.8339496 73.6653358,90.8339496 L101.332636,90.8592156 Z" id="path18184"/>
            <path d="M101,61.8230048 L101,43.0174721 C101,43.0174721 44.4184534,43 39.5965655,43 C22.2401868,43 0,55.11181 0,81.2585801 C0,107.39906 25.9664806,119.47383 37.3743909,128.522262 C39.020914,129.822184 40.1907807,133.101865 35.3007964,133.101865 L2.47594338,133.101865 L2.47594338,152 L51.7596411,152 C70.1559052,152 72.9722949,150.963907 75.6339459,145.355374 C77.4908991,141.466092 76.9709564,137.385666 67.624253,128.959763 C58.2837609,120.54015 38.7114014,106.215503 33.2209907,102.233794 C27.7305975,98.2457951 16.9045148,88.4948105 18.6871955,78.2998607 C20.4636823,68.1173161 26.6845008,61.916655 40.1907807,61.916655 C54.1860695,61.916655 101,61.8292947 101,61.8292947" id="path18188"/>
        </g>
    </g>
</SvgIcon>
  );
}
