import React from "react";
import { ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import { useTheme } from "styled-components";
import { Box } from "../../core/box";

interface LogosRowItem {
  Icon: ReactNode;
  tooltip?: string;
}

export interface LogosRowProps {
  rowId?: string;
  height?: string;
  items: LogosRowItem[];
  items2?: LogosRowItem[];
}

export function LogosRow(props: LogosRowProps) {
  const { rowId, items, height } = props;

  const { colors } = useTheme();

  return (
    <Box display="flex" color="icon.loud" justifyContent={["space-around", "space-around", "space-between"]}>
      {items.map((item, index) => (
        <Box key={index} height={["1.75rem", (height ? height : "3.25rem")]} data-for={rowId} data-tip={item.tooltip} data-place="bottom">
          {item.Icon}
        </Box>
      ))}
      <ReactTooltip
        id={rowId}
        effect="solid"
        className="tooltip"
        backgroundColor={colors.background.inverse}
        textColor={colors.text.inverse}
      />
    </Box>
  );
}
