import React, { ReactNode } from "react";
import { Box, BoxProps } from "../../../core/box";
import { Typography } from "../../../core/Typography/Typography";
import { LogosRow, LogosRowProps } from "../../../ui/LogosRow/LogosRow";

export interface BrandProvidersProps extends LogosRowProps, Pick<BoxProps, "gridArea"> {
  title: ReactNode;
}

export function BrandProviders(props: BrandProvidersProps) {
  const { gridArea, title, items, items2 } = props;

  return (
    <Box gridArea={gridArea}>
      <Box textAlign="center" marginX="auto" width={["100%", 4 / 5]}>
        <Typography variant="h4" fontSize={[1, 4]} lineHeight={[2, 1]} fontWeight={1} opacity={[1, 0.8]}>
          {title}
        </Typography>
      </Box>
      <Box marginBottomSpacing={[4, 6.375]} />
      <Box marginX="auto" width={["100%", "100%"]}>
        <LogosRow rowId="providers-row" items={items} />
      </Box>
      <Box marginBottomSpacing={[4, 6.375]} />
      <Box marginX="auto" width={["100%", "100%"]}>
        <LogosRow rowId="providers-row" items={items2} />
      </Box>
    </Box>
  );
}
