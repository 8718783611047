import React, { useRef, useEffect, useState } from "react";
import { useIntersection } from "react-use";
import { AppThemeVariant } from "../../../../types";
import { Box, BoxProps } from "../../core/box";
import { Typography } from "../../core/Typography/Typography";
import { LogosRow, LogosRowProps } from "../../ui/LogosRow/LogosRow";
import { SectionHeader } from "../../ui/SectionHeader/SectionHeader";
import { highlightText, Highlight } from "../../utils/highlightText";
import { QuotesWallSection, QuotesWallSectionProps } from "../QuotesWallSection/QuotesWallSection";
import { Section, SectionProps } from "../Section/Section";
import { Graphic } from "./Graphic";

export interface SolutionsAndBussinessSectionProps extends QuotesWallSectionProps, SectionProps {
  theme: AppThemeVariant;
  gridTemplate: BoxProps["gridTemplate"];
  platforms: LogosRowProps["items"];
  title: string;
  highlights: Highlight[];
  pre: string;
  highlightsPre: Highlight[];
  pre1: string;
  highlightsPre1: Highlight[];
  subtitle: string;
  highlightsSubtitle: Highlight[];
}

export function GraphicContainer() {
  const devicesGraphic = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const intersection = useIntersection(devicesGraphic, {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });
  const isIntersecting = intersection?.isIntersecting;

  useEffect(() => {
    if (isIntersecting) {
      document.addEventListener("scroll", onScroll, { passive: true });
    } else {
      document.removeEventListener("scroll", onScroll);
    }

    function onScroll() {
      if (devicesGraphic.current) {
        const { top } = devicesGraphic.current.getBoundingClientRect();
        const newValue = -(window.innerHeight - top) / 5;
        // max is 56
        if (top < window.innerHeight && newValue > -80) {
          setOffsetTop(newValue + 80);
        }
      }
    }

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [isIntersecting]);

  return (
    <Box gridArea="graphic" ref={devicesGraphic}>
      <Graphic offsetTop={offsetTop} />
    </Box>
  );
}

export function SolutionsAndBussinessSection(props: SolutionsAndBussinessSectionProps) {
  const { theme, gridTemplate, title, highlights, subtitle, highlightsSubtitle, pre, pre1, highlightsPre, highlightsPre1, platforms, quotes } = props;
  const formattedTitle = highlightText(title, highlights, (keyword, accent) => (
    <Box as="span" color={accent}>
      {keyword}
    </Box>
  ));

  const formattedPre1 = highlightText(pre1, highlightsPre1, (keyword, accent) => (
    <Box as="span" color={accent}>
      {keyword}
    </Box>
  ));

  const formattedPre = highlightText(pre, highlightsPre, (keyword, accent) => (
    <Box as="span" color={accent}>
      {keyword}
    </Box>
  ));

  const formattedSubtitle = highlightText(subtitle, highlightsSubtitle, (keyword, accent) => (
    <Box as="span" color={accent}>
      {keyword}
    </Box>
  ));

  return (
    <Section theme={theme}>
      <Box display="grid" gridTemplate={gridTemplate}>
        {/* <Box gridArea="title">
          <SectionHeader
            title={formattedTitle}
            width={["100%", "100%", 4 / 5, 2 / 3]}
            textAlign="center"
            marginX="auto"
          />
        </Box> */}
        {/* <GraphicContainer /> */}
        {/* <Box gridArea="platforms" marginX="auto" width={["100%", "100%", 4 / 5]}>
          <LogosRow rowId="platforms-row" items={platforms} />
        </Box> */}
        <Box gridArea="subtitle" textAlign="left" marginX="auto" width={["100%", "100%"]}>
          <Typography variant="h2" fontSize={[4, 6]} lineHeight={[1, 0]} marginBottom={10}>
            {formattedPre1}
          </Typography>
          <Typography variant="h2" fontSize={[4, 6]} lineHeight={[1, 0]} marginBottom={10}>
            {formattedPre}
          </Typography>
          <Typography variant="h2" fontSize={[4, 6]} lineHeight={[1, 0]}>
            {formattedSubtitle}
          </Typography>
        </Box>
      </Box>
      <Box marginBottomSpacing={[5.5, 15]} />
      <QuotesWallSection quotes={quotes} />
    </Section>
  );
}
