import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconWago(props: SvgIconProps) {
  return (
    <SvgIcon width="179px" height="70px" viewBox="0 0 179 70"  fill="none" {...props}>
        <g id="wago" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="WAGO_Logo" transform="translate(26.000000, 15.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M83.5,21.0358951 L83.5,23.9723884 L87.6079755,23.9723884 L87.6079755,26.6148182 L79.3920245,26.6148182 L79.3920245,18.3934653 L97,18.3934653 L97,9 L73.5199387,9 C71.5759302,9 70,10.576172 70,12.5204786 L70,32.4795214 C70,34.423828 71.5759302,36 73.5199387,36 L93.4759202,36 C95.4199287,36 96.9958589,34.423828 96.9958589,32.4795214 L96.9958589,21.0358951 L83.5,21.0358951 Z" id="Path"></path>
                <path d="M123.479521,9 L103.520479,9 C101.577118,9.00228228 100.002282,10.5771181 100,12.5204786 L100,32.4795214 C100,34.423828 101.576172,36 103.520479,36 L123.479521,36 C125.423828,36 127,34.423828 127,32.4795214 L127,12.5204786 C127,10.576172 125.423828,9 123.479521,9 M117.610676,26.6106765 L109.389324,26.6106765 L109.389324,18.3934653 L117.606535,18.3934653 L117.610676,26.6106765 Z" id="Shape"></path>
                <path d="M51.4558429,10 L32.3151626,39.3920705 L29,39.3920705 L33.3019637,46 L47.4923275,39.3920705 L43.3493937,39.3920705 L48.2426226,31.8976618 L57.7721859,31.8976618 L57.7721859,36.5171128 L67,36.5171128 L67,10.0040664 L51.4558429,10 Z M51.2234145,27.29041 L57.7477197,17.2707557 L57.7477197,27.29041 L51.2234145,27.29041 Z" id="Shape"></path>
                <polygon id="Path" points="10.9437378 36 18.9212641 23.6882765 18.9212641 36 29.9745611 36 49.0216153 6.60718319 52 6.60718319 47.7190792 0 33.5981272 6.60718319 37.9480297 6.60718319 28.1242294 21.7650779 28.1242294 9.48994805 17.1520874 9.48994805 9.20296528 21.756946 9.20296528 9.48994805 0 9.48994805 0 36"></polygon>
            </g>
        </g>
    </SvgIcon>
  );
}
