import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function IconMining() {
  return (
    <StaticImage
      src="../../../../images/icons/axe-icon.svg" 
      alt="Agua"
      draggable={false}
    />
  );
}
