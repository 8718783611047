import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageDashboard() {
  return (
    <StaticImage
      src="../../../../images/dashboard-indicadores.png" 
      alt="Dashboard 1"
      draggable={false}
    />
  );
}
