// cSpell:ignore swiper
import React from "react";
import styled from "styled-components";
import { A11y, Keyboard, Mousewheel } from "swiper";
/* eslint-disable import/no-unresolved */
import "swiper/css";
import "swiper/css/a11y";
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from "swiper/react";
/* eslint-enable import/no-unresolved */
import { Box, BoxProps } from "../../core/box";
import { PagerButton } from "../../core/PagerButton/PagerButton";
import { CaseStudyCard, CaseStudyCardProps } from "../../ui/CaseStudyCard/CaseStudyCard";

const swiperProps: SwiperProps = {
  keyboard: true,
  loop: false, // this doesn't look right
  grabCursor: true,
  autoHeight: true,
  spaceBetween: 26,
  mousewheel: { forceToAxis: true, thresholdDelta: 20 },
  breakpoints: {
    320: {
      slidesPerView: 1.125,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 1.5,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
    },
    960: {
      slidesPerView: 2.225,
    },
    1024: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 3,
    },
  },
};

const Container = styled(Box)`
  user-select: none;
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    display: flex;
    align-self: stretch;
  }
`;

type PagerProps = Omit<BoxProps, "color">;
function Pager(props: PagerProps) {
  const swiper = useSwiper();

  return (
    <Box {...props}>
      <PagerButton
        type="button"
        title="Slide to previous quote card"
        onClick={() => swiper.slidePrev()}
        direction="left"
      />
      <Box marginRightSpacing={1.75} />
      <PagerButton type="button" title="Slide to next quote card" onClick={() => swiper.slideNext()} />
    </Box>
  );
}

export interface QuotesWallSectionProps {
  quotes: CaseStudyCardProps[];
}

export function QuotesWallSection(props: QuotesWallSectionProps) {
  const { quotes } = props;

  return (
    <Container>
      <Swiper modules={[A11y, Keyboard, Mousewheel]} {...swiperProps}>
        {quotes.map((quote, index) => (
          <SwiperSlide key={index}>
            <CaseStudyCard {...quote} />
          </SwiperSlide>
        ))}
        <Box marginBottomSpacing={6.5} />
        <Pager display={["none", "flex"]} />
      </Swiper>
    </Container>
  );
}
