import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconElo(props: SvgIconProps) {
  return (
    <SvgIcon width="179px" height="70px" viewBox="0 0 179 70" fill="none" {...props}>
      <g id="elo_touch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="elo-touch-solutions" transform="translate(57.000000, 12.000000)" fill="#FFFFFF">
              <polygon id="Fill-1" points="24 45 32 45 32 0 24 0"></polygon>
              <path d="M11.9330037,14.2022309 C11.9330037,12.5561609 11.1772212,12.4299824 10.0012761,12.4299824 C8.82309778,12.4299824 8.06731536,12.5561609 8.06731536,14.2022309 L8.06731536,23.1251252 L11.9330037,23.1251252 L11.9330037,14.2022309 Z M9.98341043,45 C8.06731536,45 5.72691019,44.6320061 5.72691019,44.6320061 C2.12601691,43.7813396 0,41.7781365 0,39.0089184 L0,14.0750941 C0,10.3414891 3.86600734,8 10.0012761,8 C16.1346307,8 20,10.3414891 20,14.0750941 L20,27.3644024 L8.06731536,27.3644024 L8.06731536,38.8827399 C8.06731536,40.5284905 8.82309778,40.6537107 10.0012761,40.6537107 C11.1772212,40.6537107 11.9330037,40.5284905 11.9330037,38.8827399 L11.9330037,30.4029078 L20,30.4029078 L20,39.0089184 C20,41.7845253 17.8644122,43.789645 14.2498006,44.6374365 L14.2498006,44.6415893 C14.2498006,44.6415893 11.7224438,45 9.98341043,45 L9.98341043,45 Z" id="Fill-2"></path>
              <path d="M47.9329367,14.2014813 C47.9329367,12.5556102 47.1771181,12.429447 46.0001595,12.429447 C44.8228819,12.429447 44.0670633,12.5556102 44.0670633,14.2014813 L44.0670633,38.8790076 C44.0670633,40.5232817 44.8228819,40.6497643 46.0001595,40.6497643 C47.1771181,40.6497643 47.9329367,40.5245593 47.9329367,38.8790076 L47.9329367,14.2014813 Z M46.2735814,45 C44.5357411,45 41.7769553,44.637161 41.7769553,44.637161 L41.7453699,44.6317312 C38.1328186,43.7837227 36,41.7791647 36,39.0051708 L36,14.0743599 C36,10.3412061 39.8652352,8 46.0001595,8 C52.1354029,8 56,10.3412061 56,14.0743599 L56,39.0051708 C56,41.7839557 53.8614386,43.7904301 50.2386779,44.6362028 L50.253673,44.637161 C50.253673,44.637161 47.9329367,45 46.2735814,45 L46.2735814,45 Z" id="Fill-3"></path>
              <polyline id="Fill-4" points="0 0 0 5 20 4.99945667 20 0 0 0"></polyline>
          </g>
      </g>
    </SvgIcon>
  );
}
