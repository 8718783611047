import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageIntegration() {
  return (
    <StaticImage
      src="../../../../images/integracion-comunicacion.png" 
      alt="Integracion"
      draggable={false}
    />
  );
}
