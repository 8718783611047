import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageScada() {
  return (
    <StaticImage
      src="../../../../images/scada.png" 
      alt="SCADA"
      draggable={false}
    />
  );
}
