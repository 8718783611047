import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconApple(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        d="M35.0344 25.1109C35.0156 21.914 36.4641 19.5046 39.3891 17.7281C37.7531 15.3843 35.2781 14.0953 32.0156 13.8468C28.9266 13.6031 25.5469 15.6468 24.3094 15.6468C23.0016 15.6468 20.0109 13.9312 17.6578 13.9312C12.8016 14.0062 7.64062 17.8031 7.64062 25.5281C7.64062 27.8109 8.05781 30.1687 8.89219 32.5968C10.0078 35.7937 14.0297 43.6265 18.225 43.4999C20.4187 43.4484 21.9703 41.9437 24.825 41.9437C27.5953 41.9437 29.0297 43.4999 31.4766 43.4999C35.7094 43.439 39.3469 36.3187 40.4062 33.1124C34.7297 30.4359 35.0344 25.2749 35.0344 25.1109ZM30.1078 10.814C32.4844 7.99213 32.2688 5.42338 32.1984 4.49994C30.0984 4.62181 27.6703 5.92963 26.2875 7.53744C24.7641 9.26244 23.8687 11.3953 24.0609 13.7999C26.3297 13.9734 28.4016 12.8062 30.1078 10.814Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
