import React from "react";
import { Box, BoxProps } from "../../core/box";
import { Typography, TypographyProps } from "../../core/Typography/Typography";
import { Highlight, highlightText } from "../../utils/highlightText";

export interface QuoteBlockProps extends BoxProps {
  text?: string;
  textProps?: TypographyProps;
  color?: string;
  highlights?: Highlight[];
  authorName?: string;
  authorPosition?: string;
}

export function QuoteBlock(props: QuoteBlockProps) {
  const { text = "", textProps, color, highlights = [], authorName, authorPosition, ...otherProps } = props;

  const formattedText =
    highlights.length > 0
      ? highlightText(text, highlights, (keyword, accent) => (
          <Box as="strong" fontWeight="inherit" color={accent}>
            {keyword}
          </Box>
        ))
      : text;

  return (
    <Box paddingXSpacing={[0, 0, 6]} width={["100%", "100%", 3 / 4]} {...otherProps}>
      <Typography variant="h3" color={color} {...textProps}>
        {formattedText}
      </Typography>
      {authorName && (
        <Typography variant="h4" lineHeight={1} marginTopSpacing={[2.5, 3.75]}>
          {authorName}
        </Typography>
      )}
      {authorPosition && (
        <Typography variant="h4" lineHeight={1} color="text.disabled">
          {authorPosition}
        </Typography>
      )}
    </Box>
  );
}
