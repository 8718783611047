import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function IconHealth() {
  return (
    <StaticImage
      src="../../../../images/icons/health-and-safety-icon.svg" 
      alt="Agua"
      draggable={false}
    />
  );
}
