import React from "react";
import { SvgIcon, SvgIconProps } from "../../core/svgicon";

export function IconSmartRouting(props: SvgIconProps) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14ZM16.8293 15C16.4175 16.1652 15.3062 17 14 17C12.3431 17 11 15.6569 11 14C11 12.3431 12.3431 11 14 11C15.3062 11 16.4175 11.8348 16.8293 13H19H21V15V25H23.1707C23.5825 23.8348 24.6938 23 26 23C27.6569 23 29 24.3431 29 26C29 27.6569 27.6569 29 26 29C24.6938 29 23.5825 28.1652 23.1707 27H21H19V25V15H16.8293ZM26 27C26.5523 27 27 26.5523 27 26C27 25.4477 26.5523 25 26 25C25.4477 25 25 25.4477 25 26C25 26.5523 25.4477 27 26 27Z"
        fill="currentColor"
      />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
}
