import React from "react";
import { Box } from "../../core/box";
import { Card, CardProps } from "../../core/Card/Card";
import { Grid } from "../../core/Grid/Grid";
import { LinkStyleButton, LinkStyleButtonProps } from "../../core/LinkStyleButton/LinkStyleButton";
import { Typography } from "../../core/Typography/Typography";

import { Caption, CaptionProps } from "../../ui/Caption/Caption";
import { QuoteBlock, QuoteBlockProps } from "../../ui/QuoteBlock/QuoteBlock";
import { SectionHeader, SectionHeaderProps } from "../../ui/SectionHeader/SectionHeader";
import { Section, SectionProps } from "../Section/Section";



export interface PromoMeterIOTSectionProps {
  sectionProps: SectionProps;
  headerProps: SectionHeaderProps;
  textCardProps: {
    // cardProps: CardProps;
    // captionProps: CaptionProps;
    display: string[];
    gridColumn: string[];
    gridRow: string;
    children: string;
    captionProps? : {
      Icon: any;
      text: string;
    }
    ImageSvg?: any;
  };
  codeProps: {
    cardProps: CardProps;
    ImageSvg?: any;
  };
  serviceAccountsProps: {
    cardProps: Omit<CardProps, "display" | "flexDirection" | "columnGap">;
    captionProps: CaptionProps;
    // linkProps: LinkStyleButtonProps;
    image: any;
  };
  terraformProviderProps: {
    cardProps: Omit<CardProps, "display" | "flexDirection" | "columnGap">;
    captionProps: CaptionProps;
    image: any;
    // linkProps: LinkStyleButtonProps;
  };
  quoteBlockProps: QuoteBlockProps;
}

export function PromoMeterIOTSection(props: PromoMeterIOTSectionProps) {
  const {
    sectionProps,
    headerProps,
    textCardProps,
    codeProps,
    serviceAccountsProps,
    terraformProviderProps,
    quoteBlockProps,
  } = props;

  return (
    <Section {...sectionProps}>
      <Grid>
        {/* Section Header  */}
        <SectionHeader paddingLeftSpacing={[0, 0, 6]} paddingBottomSpacing={[4, 5]} {...headerProps} />

        {/* Text Card  */}
        <Card {...textCardProps} rowGap={["1.875rem", "2rem"]}>
          <Caption {...textCardProps.captionProps} />
          <Box marginBottom={10} marginTop={2}>
            {textCardProps.ImageSvg}
          </Box>
          <Typography as="p" variant="h3" fontWeight={1} fontSize={[4, 4, 6]} paddingRightSpacing={[0, 0, 6]}>
            {textCardProps.children}
          </Typography>
        </Card>

        {/* Code Card  */}
        <Card {...codeProps.cardProps} padding={3}>
          <Box >
            {codeProps.ImageSvg}
          </Box>
        </Card>

        <Card display="flex" flexDirection="column" rowGap={["1.875rem", "2rem"]} {...serviceAccountsProps.cardProps}>
          <Caption {...serviceAccountsProps.captionProps} />
          <Box marginXSpacing={[-3, -6]}>
            {serviceAccountsProps.image}
          </Box>
          <Typography as="p" variant="h3" fontWeight={1} fontSize={[4, 4, 6]} paddingRightSpacing={[0, 0, 6]}>
            {serviceAccountsProps.cardProps.children}
          </Typography>
          {/* <Box marginTop="auto">
            <LinkStyleButton {...serviceAccountsProps.linkProps} />
          </Box> */}
        </Card>

        <Card display="flex" flexDirection="column" rowGap={["1.875rem", "2rem"]} {...terraformProviderProps.cardProps}>
          <Caption {...terraformProviderProps.captionProps} />
          <Box marginXSpacing={[-3, -6]}>
            {terraformProviderProps.image}
          </Box>
          <Typography as="p" variant="h3" fontWeight={1} fontSize={[4, 4, 6]} paddingRightSpacing={[0, 0, 6]}>
            {terraformProviderProps.cardProps.children}
          </Typography>
          {/* <Box marginTop="auto">
            <LinkStyleButton {...terraformProviderProps.linkProps} />
          </Box> */}
        </Card>

        {/* Quote Block  */}
        {/* <QuoteBlock marginTopSpacing={[2.5, 3.75]} {...quoteBlockProps} /> */}
      </Grid>
    </Section>
  );
}
