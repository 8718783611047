import React from "react";
import { Grid } from "../../core/Grid/Grid";
import { CaseStudyCard, CaseStudyCardProps } from "../../ui/CaseStudyCard/CaseStudyCard";
import { SectionHeader, SectionHeaderProps } from "../../ui/SectionHeader/SectionHeader";
import { Section, SectionProps } from "../Section/Section";

export interface BlogProjectsSectionProps {
  sectionProps: SectionProps;
  headerProps: SectionHeaderProps;
  caseStudiesCardsProps: CaseStudyCardProps[];
}

export function BlogProjectsSection(props: BlogProjectsSectionProps) {
  const { sectionProps, headerProps, caseStudiesCardsProps = [] } = props;

  return (
    <Section paddingYSpacing={[7, 15]} paddingBottom={[8, 18]} {...sectionProps}>
      <Grid>
        <SectionHeader paddingLeftSpacing={[0, 0, 6]} marginBottomSpacing={[3.5, 3.5, 6.5]} {...headerProps} />
        {caseStudiesCardsProps.map((card, idx) => (
          <CaseStudyCard key={idx} quoteVariant="h3" {...card} />
        ))}
      </Grid>
    </Section>
  );
}
