import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageSecTelemetry() {
  return (
    <StaticImage
      src="../../../../images/sec-telemetry.png" 
      alt="Telemetría"
      draggable={false}
    />
  );
}
