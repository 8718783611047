import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconWindows(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        d="M24.553 8.97183V22.514H42.4592V6.00464L24.553 8.97183ZM24.553 39.075L42.4592 41.9906V25.4906H24.553V39.075ZM5.62955 22.5093H20.7655V9.46401L5.62955 11.9718V22.5093ZM5.62955 36.1218L20.7655 38.5875V25.4906H5.62955V36.1218Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
