import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconChrome(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <g clipPath="url(#clip0_911_162694)">
        <path
          d="M20.7456 31.4655C18.6967 31.4655 16.7891 30.9003 15.0934 29.7698C13.3977 28.6394 12.1259 27.2263 11.2781 25.46L2.79974 10.7642C1.03341 13.9435 0.256256 17.4055 0.256256 21.0088C0.256256 26.1665 1.95191 30.6176 5.27263 34.4329C8.59336 38.2482 12.7618 40.5091 17.7076 41.2863L23.6425 30.9709C23.0772 31.1829 22.0174 31.4655 20.7456 31.4655Z"
          fill="currentColor"
        />
        <path
          d="M14.315 12.5306C16.2226 11.0469 18.3422 10.411 20.7444 10.411H38.337C36.5 7.30226 34.0272 5.0413 30.9184 3.27497C27.8097 1.43805 24.4183 0.519554 20.7444 0.519554C17.565 0.519554 14.5269 1.22612 11.7715 2.56849C9.01609 3.91087 6.40185 5.88921 4.56485 8.4327L10.4997 18.1828C11.0649 15.9219 12.4073 14.0143 14.315 12.5306Z"
          fill="currentColor"
        />
        <path
          d="M39.6824 13.3076H27.8128C29.8617 15.3566 31.2747 18.0414 31.2747 21.0088C31.2747 23.1991 30.6389 25.1774 29.4377 27.0143L20.9594 41.4982C26.541 41.4275 31.3454 39.4493 35.3019 35.4221C39.2585 31.3949 41.2368 26.5904 41.2368 21.0088C41.2368 18.3947 40.8129 15.6392 39.6824 13.3076Z"
          fill="currentColor"
        />
        <path
          d="M20.7468 13.2368C24.986 13.2368 28.5186 16.7695 28.5186 21.0086C28.5186 25.2477 24.986 28.7804 20.7468 28.7804C16.5076 28.7804 12.975 25.2478 12.975 21.0086C12.975 16.7694 16.5076 13.2368 20.7468 13.2368Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_911_162694">
          <rect width="40.9788" height="40.9788" fill="white" transform="translate(0.256256 0.52002)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
