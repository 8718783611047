import { AnimatePresence, motion, Transition, Variants } from "framer-motion";
import { findIndex } from "lodash";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { Box } from "../../core/box";
import { Card, CardProps } from "../../core/Card/Card";
import { DeploymentButton } from "../DeploymentButton/DeploymentButton";
import { Typography } from "../../core/Typography/Typography";

const cliCommandVariants: Variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 10 : -10,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? 10 : -10,
    opacity: 0,
  }),
};

const cliCommandTransition: Transition = {
  opacity: { duration: 0.2 },
};

const MotionBox = motion(Box);
const CodeContainer = styled(Box)`
  position: relative;
  ::before,
  ::after {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    right: 0;
    content: "";
  }
  ::before {
    left: 0;
    background: linear-gradient(0, ${({ theme }) => theme.colors.background.faint} 28.51%, rgba(29, 32, 35, 0) 76.12%);
    height: 5.4375rem;
    z-index: 1;
  }
  ::after {
    top: 0;
    background: linear-gradient(
      270deg,
      ${({ theme }) => theme.colors.background.faint} 28.51%,
      rgba(29, 32, 35, 0) 76.12%
    );
    width: 5.4375rem;
  }
`;

type ServicesElementsMethod = {
  name: string;
  cliCommand: ReactNode;
  textBody: string;
  imageBody: any;
};

export interface ServicesElementsCardProps extends CardProps {
  servicesElementsMethods: ServicesElementsMethod[];
}

export function ServicesElementsCard(props: ServicesElementsCardProps) {
  const { gridArea, display, servicesElementsMethods } = props;
  const [[selectedServicesElementsMethod, direction], setSelectedServicesElementsMethod] = useState<[ServicesElementsMethod, number]>([
    servicesElementsMethods[0],
    0,
  ]);

  function handleServicesElementsButtonClick(nextServicesElementsMethod: ServicesElementsMethod) {
    const nextDeploymentMethodIndex = findIndex(servicesElementsMethods, nextServicesElementsMethod);
    const selectedServicesElementsMethodIndex = findIndex(servicesElementsMethods, selectedServicesElementsMethod);
    const direction = nextDeploymentMethodIndex > selectedServicesElementsMethodIndex ? 1 : -1;

    setSelectedServicesElementsMethod([nextServicesElementsMethod, direction]);
  }

  const { textBody, imageBody } = selectedServicesElementsMethod;

  return (
    <Card display={display} gridArea={gridArea}>
      <Box
        display="grid"
        gridRowGap="1rem"
        gridColumnGap={["0.75rem", "1rem"]}
        gridTemplateColumns={["repeat(3, minmax(10px, 1fr))", "repeat(auto-fit, minmax(100px, 1fr))"]}
      >
        {servicesElementsMethods.map((serviceElementMethod) => {
          const { name, textBody } = serviceElementMethod;
          const isActive = name === selectedServicesElementsMethod.name;

          return (
            <DeploymentButton
              key={name}
              isActive={isActive}
              type="button"
              aria-pressed={isActive}
              onClick={() => handleServicesElementsButtonClick(serviceElementMethod)}
            >
              {name}
            </DeploymentButton>
          );
        })}
      </Box>
      <Box marginBottomSpacing={4} />

      <Box paddingBottom={`${(20 / 1394) * 100}%`}>
        <Typography>{imageBody}</Typography>
        <Typography>{textBody}</Typography>
      </Box>

      {/* <CodeContainer
        overflow="hidden"
        marginXSpacing={[-3, -6]}
        paddingXSpacing={[3, 6]}
        marginBottomSpacing={[-3, -6]}
        paddingBottomSpacing={[3, 6]}
      >
        <Box paddingBottom={`${(624 / 1394) * 100}%`}>
          <AnimatePresence initial={false} custom={direction}>
            <MotionBox
              position="absolute"
              key={selectedServicesElementsMethod.name}
              variants={cliCommandVariants}
              transition={cliCommandTransition}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
            >
              {cliCommand}
            </MotionBox>
          </AnimatePresence>
        </Box>
      </CodeContainer> */}
    </Card>
  );
}
