import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconIOS(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        d="M1.21385 35.457H5.0178V19.2636H1.21385V35.457ZM3.1082 17.14C4.2998 17.14 5.23169 16.2234 5.23169 15.0777C5.23169 13.9166 4.2998 13 3.1082 13C1.93188 13 1 13.9166 1 15.0777C1 16.2234 1.93188 17.14 3.1082 17.14V17.14ZM17.6829 13.0457C11.2514 13.0457 7.21814 17.4302 7.21814 24.4424C7.21814 31.4546 11.251 35.8239 17.6829 35.8239C24.0991 35.8239 28.1324 31.4546 28.1324 24.4424C28.1324 17.4302 24.0995 13.0457 17.6829 13.0457ZM17.6829 16.4066C21.609 16.4066 24.1143 19.5232 24.1143 24.4423C24.1143 29.3462 21.6089 32.4628 17.6829 32.4628C13.7415 32.4628 11.2514 29.3463 11.2514 24.4423C11.2514 19.5232 13.7416 16.4066 17.6829 16.4066V16.4066ZM29.737 29.2547C29.905 33.3185 33.2354 35.8238 38.3073 35.8238C43.639 35.8238 47 33.1961 47 29.0102C47 25.7257 45.1056 23.8772 40.6296 22.8537L38.0936 22.2732C35.3896 21.6316 34.2744 20.7761 34.2744 19.3095C34.2744 17.4763 35.9549 16.2541 38.4449 16.2541C40.9656 16.2541 42.6918 17.4916 42.8752 19.5539H46.6333C46.5416 15.6736 43.3335 13.0458 38.4756 13.0458C33.6785 13.0458 30.2718 15.6887 30.2718 19.5997C30.2718 22.7467 32.1967 24.7022 36.2601 25.6342L39.1169 26.3064C41.8973 26.9633 43.0278 27.8799 43.0278 29.4687C43.0278 31.3019 41.1792 32.6157 38.5213 32.6157C35.8325 32.6157 33.8008 31.2866 33.5564 29.2548H29.7372L29.737 29.2547Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
