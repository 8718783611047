
import React from "react";
import { motion, Transition, Variants } from "framer-motion";
import styled from "styled-components";
// import { CustomerKey } from "../../../../data/nodes/customers/customers";
import { links } from "../../../../data/site";
import { Box, BoxProps } from "../../core/box";
import { PagerButton } from "../../core/PagerButton/PagerButton";
import { Typography, TypographyProps } from "../../core/Typography/Typography";
import { getCustomerLogo } from "../../utils/getCustomerLogo";
import { Highlight, highlightText } from "../../utils/highlightText";

const transition: Transition = {
  type: "ease-out",
  duration: 0.125,
};

const moreLinkVariants: Variants = {
  initial: {
    paddingRight: "0.5rem",
  },
  hover: {
    paddingRight: "1.5rem",
  },
};

const moreLinkTextVariants: Variants = {
  initial: {
    width: 0,
  },
  hover: {
    width: "auto",
  },
};

const MotionBox = motion(Box);
const StyledPagerButton = styled(PagerButton)<typeof PagerButton>`
  position: relative;
  z-index: 1;
`;

const MotionPagerButton = motion(StyledPagerButton);

const BlockQuote = styled(Box).attrs({ as: "blockquote" })<{ variant: "caseStudy" | "quote" }>`
  border: 0.125rem solid;

  ${(props) => {
    const { variant, theme } = props;

    if (variant === "caseStudy") {
      return `
        border-color: ${theme.colors.text.loud};
      `;
    }

    return `
      border-color: ${theme.colors.border.faint};
      ${StyledPagerButton} {
        visibility: hidden;
      }
    `;
  }}
`;

const MotionBlockQuote = motion(BlockQuote);

export interface CaseStudyCardProps extends Omit<BoxProps, "as" | "color" | "transition"> {
  variant: "caseStudy" | "quote";
  caption?: string;
  quote: string;
  quoteVariant?: TypographyProps["variant"];
  highlights: Highlight[];
  source: string;
  icon?: any;
  // customer: 'string';
}

export function CaseStudyCard(props: CaseStudyCardProps) {
  const { variant = "quote", caption, quote, quoteVariant = "h4", highlights, source, icon, ...otherProps } = props;

  const formattedQuote = highlightText(quote, highlights, (keyword, accent) => (
    <Box as="strong" fontWeight="inherit" color={accent}>
      {keyword}
    </Box>
  ));

  // const Logo = getCustomerLogo(customer);

  return (
    <MotionBlockQuote
      initial="initial"
      whileHover="hover"
      display="flex"
      variant={variant}
      flexDirection="column"
      backgroundColor="background.base"
      borderRadius={4}
      minHeight={["18.6875rem", "27.625rem"]}
      paddingTopSpacing={[2.75, 4.5]}
      paddingLeftSpacing={[2.75, 4.5]}
      paddingBottomSpacing={2.75}
      paddingRightSpacing={2.75}
      {...otherProps}
    >
      <Box flexGrow={1} paddingBottomSpacing={4}>
        {caption && (
          <Box marginBottomSpacing={2}>
            <Typography variant="label2" color="text.muted">
              {caption}
            </Typography>
          </Box>
        )}
        <Typography variant={quoteVariant} color="text.disabled" fontWeight={1} lineHeight={1}>
          {formattedQuote}
        </Typography>
      </Box>
      <Box as="footer" minHeight={["5.375rem", "6rem"]} display="flex" flexDirection="column" justifyContent="flex-end">
        {source && (
          <Typography display="block" as="cite" variant="body3" marginBottomSpacing={[2]}>
            {source}
          </Typography>
        )}
        <Box display="flex" position="relative" justifyContent="flex-end" minHeight="2.75rem">
          {icon ? (<Box position="absolute" left={0} top="50%" transform="translateY(-50%)">
            <Box color="text.loud" width={["1rem", "2.3rem"]}>
              {icon}
            </Box>
          </Box>):(<></>)}
          {variant === "caseStudy" && (
            <MotionPagerButton
              variants={moreLinkVariants}
              to={`${links.blog.path}${source}/`}
              transition={transition}
            >
              <MotionBox variants={moreLinkTextVariants} transition={transition} display="flex" overflow="hidden">
                <Typography
                  position="relative"
                  top="1px"
                  variant="button"
                  color="inherit"
                  whiteSpace="nowrap"
                  paddingLeftSpacing={1.25}
                >
                 Conoce más 
                </Typography>
              </MotionBox>
            </MotionPagerButton>
          )}
        </Box>
      </Box>
    </MotionBlockQuote>
  );
}
