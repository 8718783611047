import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function IconIndustry() {
  return (
    <StaticImage
      src="../../../../images/icons/sewer-icon.svg" 
      alt="Agua"
      draggable={false}
    />
  );
}
