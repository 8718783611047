import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import React, { ButtonHTMLAttributes, ElementType, ForwardedRef, forwardRef } from "react";
import styled, { css } from "styled-components";
import { IconArrowRight } from "../../icons/IconArrowRight";
import { Box } from "../box";

const sharedStyle = css`
  background: ${({ theme }) => theme.colors.background.base};
  color: ${({ theme }) => theme.colors.text.loud};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 2.75rem;
  min-width: 1.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.text.loud};
  border-radius: 2.75rem;
  padding: 0 0.5rem;
  transition: all 0.1s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colors.button.primary.inactive.color};
    background-color: ${({ theme }) => theme.colors.button.primary.inactive.backgroundColor};
  }
`;

const Button = styled("button")`
  ${sharedStyle}
`;
const Link = styled(GatsbyLink)`
  ${sharedStyle}
`;

interface IconContainerProps {
  direction?: "left" | "right";
}

const IconContainer = styled(Box)<IconContainerProps>`
  height: 1.5rem;
  width: 1.5rem;

  ${(props) => {
    const { direction } = props;
    if (direction === "right") return;
    return `
      transform: rotate(180deg);
    `;
  }}
`;

type ButtonType = "submit" | "reset" | "button";
type ButtonProps<T extends ButtonType> = { type: T } & ButtonHTMLAttributes<T>;
type LinkProps = { type?: never } & Omit<GatsbyLinkProps<Record<string, unknown>>, "type">;

export type PagerButtonProps<T> = IconContainerProps & (T extends ButtonType ? ButtonProps<T> : LinkProps);

function InnerPagerButton<T>(props: PagerButtonProps<T>, ref: ForwardedRef<HTMLElement>) {
  const { direction = "right", children, ...otherProps } = props;
  const Component: ElementType = otherProps.type ? Button : Link;

  return (
    <Component ref={ref} {...otherProps}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconContainer direction={direction}>
          <IconArrowRight />
        </IconContainer>
      </Box>
      {children}
    </Component>
  );
}

export const PagerButton = forwardRef(InnerPagerButton) as <T>(
  props: PagerButtonProps<T> & { ref?: ForwardedRef<HTMLElement> },
) => ReturnType<typeof InnerPagerButton>;
