import React from "react";
import { IconAndroid } from "../../components/rebrand/icons/platform/IconAndroid";
import { IconApple } from "../../components/rebrand/icons/platform/IconApple";
import { IconChrome } from "../../components/rebrand/icons/platform/IconChrome";
import { IconIOS } from "../../components/rebrand/icons/platform/IconIOS";
import { IconLinux } from "../../components/rebrand/icons/platform/IconLinux";
import { IconWindows } from "../../components/rebrand/icons/platform/IconWindows";

import { IconWater } from "../../components/rebrand/graphics/home/IconWater";
import { IconRetail } from "../../components/rebrand/graphics/home/IconRetail";
import { IconIndustry } from "../../components/rebrand/graphics/home/IconIndustry";
import { IconMining } from "../../components/rebrand/graphics/home/IconMining";
import { IconHealth } from "../../components/rebrand/graphics/home/IconHealth";

import type { SolutionsAndBussinessSectionProps } from "../../components/rebrand/sections/SolutionsAndBussinessSection/SolutionsAndBussinessSection";

const platforms = {
  apple: {
    Icon: <IconApple />,
    tooltip: "Apple",
  },
  windows: {
    Icon: <IconWindows />,
    tooltip: "Windows",
  },
  linux: {
    Icon: <IconLinux />,
    tooltip: "Linux",
  },
  chrome: {
    Icon: <IconChrome />,
    tooltip: "Chrome",
  },
  ios: {
    Icon: <IconIOS />,
    tooltip: "iOS",
  },
  android: {
    Icon: <IconAndroid />,
    tooltip: "Android",
  },
};

export const solutionsAndBussinessData: SolutionsAndBussinessSectionProps = {
  theme: "light",
  gridTemplate: [
    // 1rem = 16px
    // mobile
    `

      "subtitle"
    `,
    // tablet/desktop
    `
      "subtitle"
    `,
  ],
  // gridTemplate: [
  //   // 1rem = 16px
  //   // mobile
  //   `
  //     "title"
  //     "." 4rem
  //     "graphic"
  //     "." 3rem
  //     "platforms"
  //     "." 3rem
  //     "subtitle"
  //   `,
  //   // tablet/desktop
  //   `
  //     "title"
  //     "." 5.25rem
  //     "graphic"
  //     "." 5.25rem
  //     "platforms"
  //     "." 5.25rem
  //     "subtitle"
  //   `,
  // ],
  title: "Tecnologías y un lenguaje moderno que configuran soluciones de clase mundial.",
  highlights: [
    {
      keyword: "soluciones de clase mundial.",
      accent: "text.purple",
    },
  ],
  pre1: "Polarycs Labs nace el año 2019 en la región de Valparaíso con foco en el desarrollo de ingeniería aplicada, logrando aportar nuevas ideas y generar soluciones innovadoras; comunicando tecnologías existentes para lograr eficiencia y optimización en el ámbito industrial y comercial.",
  highlightsPre1: [
    {
      keyword: "Polarycs Labs",
      accent: "text.red",
    },
    {
      keyword: "nuevas ideas",
      accent: "text.red",
    },
    {
      keyword: "soluciones innovadoras",
      accent: "text.red",
    },
  ],
  pre: "Nos comprometemos a entregar un servicio innovador, de excelencia y calidad, que agregue valor a nuestros clientes, combinando lo mejor de la industria 4.0 con nuestra experiencia y trayectoria recorrida.",
  highlightsPre : [
    {
      keyword: "combinando lo mejor de la industria 4.0 con nuestra experiencia",
      accent: "text.red",
    },
  ],
  subtitle: "Diferentes industrias están en la búsqueda constante de tecnologías que permitan la optimización de sus procesos, logrando un mejor control interno y la toma de decisiones eficientes en cada uno de sus negocios.",
  highlightsSubtitle : [
    {
      keyword: "búsqueda constante de tecnologías que permitan la optimización de sus procesos",
      accent: "text.red",
    },
  ],
  platforms: [platforms.apple, platforms.windows, platforms.linux, platforms.chrome, platforms.ios, platforms.android],
  quotes: [
    {
      variant: "quote",
      quote: `Identifica tus puntos de funcionamiento óptimo, mide la energía consumida, reduce fugas, entre otros, debido al desarrollo de sistemas de control para los diferentes procesos productivos y la obtención de información en tiempo real.`,
      highlights: [
        {
          keyword: `desarrollo de sistemas de control para los diferentes procesos productivos`,
          accent: "text.loud",
        },
      ],
      source: "Agua e Hidráulica",
      icon: <IconWater />
    },
    {
      variant: "quote",
      quote: `Consigue una alta eficiencia operativa, agiliza los procesos internos y reduce costos, a través de la incorporación de soluciones que permitan la automatización de sistemas, para entregar a sus consumidores una experiencia en servicio única.`,
      highlights: [
        {
          keyword: `agiliza los procesos internos y reduce costos`,
          accent: "text.loud",
        },
      ],
      source: "Retail & Commerce",
      icon: <IconRetail />
    },
    {
      variant: "quote",
      quote: `Logra el control constante, preciso y en tiempo real para niveles de líquidos, temperatura, potencia, entre otros, con el fin de obtener una fabricación eficiente, precisa y de alta calidad.`,
      highlights: [
        {
          keyword: `Logra el control constante, preciso y en tiempo real`,
          accent: "text.loud",
        },
      ],
      source: "Industria productiva",
      icon: <IconIndustry />
    },
    {
      variant: "quote",
      quote: `Controla maquinarias a distancia y conoce el desarrollo e impacto de sus tecnologías con la implementación de sistemas de control y monitoreo en línea, logrando procesos operacionales precisos y seguros.`,
      highlights: [
        {
          keyword: `implementación de sistemas de control y monitoreo en línea`,
          accent: "text.loud",
        },
      ],
      source: "Minería",
      icon: <IconMining />
    },
    {
      variant: "quote",
      quote: `Obtén eficiencia en los sistemas de atención médica y entrega de diagnósticos, gracias a una base de datos con acceso a información detallada de pacientes, permitiendo entregar un servicio que se ajuste a las necesidades de sus clientes.`,
      highlights: [
        {
          keyword: `entregar un servicio que se ajuste a las necesidades de sus clientes`,
          accent: "text.loud",
        },
      ],
      source: "Salud",
      icon: <IconHealth />
    },
  ],
};
