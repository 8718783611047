import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ScadaService() {
  return (
    <>
      {/* <StaticImage
        src="scada.png"
        alt="SCANDA"
        placeholder="none"
        draggable={false}
      /> */}
    </>
  );
}
