import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconSchneider(props: SvgIconProps) {
  return (
    <SvgIcon width="179px" height="70px" viewBox="0 0 179 70"  fill="none" {...props}>
        <defs>
        <polyline id="path-1" points="0 47 0 0 160 0 160 47"></polyline>
    </defs>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Schneider_Electric_2007" transform="translate(10.000000, 11.000000)">
            <g id="g2510" transform="translate(80.000000, 23.500000) scale(-1, 1) rotate(-180.000000) translate(-80.000000, -23.500000) ">
                <path d="M18.1991394,40.1552143 C14.5411243,41.7710749 12.3597391,42.3980289 10.19351,42.3980289 C7.92010609,42.3980289 6.46404504,41.5879441 6.46404504,40.3361907 C6.46404504,36.5583085 19.3434193,37.6215448 19.3434193,28.8829704 C19.3434193,24.064474 15.2805218,21.2647261 9.63598474,21.2647261 C5.20826003,21.2647261 3.02795745,22.4044465 0.516387453,23.6949805 L0.516387453,29.100573 C4.13867764,26.712331 6.35578771,25.8688517 9.14990933,25.8688517 C11.5727083,25.8688517 12.8793743,26.712331 12.8793743,28.1827642 C12.8793743,32.2967454 0,30.789686 0,39.7878753 C0,44.1269998 3.76843758,47 9.63598474,47 C12.4679964,47 15.0228694,46.408595 18.1991394,45.0491176 L18.1991394,40.1552143" id="path53" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M36.2315624,22.4130644 C34.0848196,21.6385285 32.2693442,21.2690351 30.4581991,21.2690351 C24.4596606,21.2690351 20.5818831,24.7452899 20.5818831,30.0345405 C20.5818831,35.2268393 24.6101383,38.894843 30.235189,38.894843 C31.9727192,38.894843 34.1941595,38.4520972 35.9706623,37.7939033 L35.9706623,33.8188861 C34.5643996,34.562182 32.9362094,34.9693789 31.6035617,34.9693789 C28.3839887,34.9693789 26.3130261,33.0443502 26.3130261,30.0733211 C26.3130261,27.0602796 28.3493464,25.07708 31.4173591,25.07708 C32.7121167,25.07708 33.8628921,25.3722439 36.2315624,26.2545038 L36.2315624,22.4130644" id="path55" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M82.998728,31.9821912 C83.2184904,34.3413477 84.5955236,35.7051341 86.7043763,35.7051341 C88.7775041,35.7051341 90.1826842,34.302567 90.4046118,31.9821912 L82.998728,31.9821912 Z M86.3341362,38.8894568 C81.070665,38.8894568 77.3238789,35.1902132 77.3238789,29.9849874 C77.3238789,24.740981 81.2200601,21.2690351 87.225094,21.2690351 C88.5555766,21.2690351 91.7729844,21.2690351 94.8377493,23.5258538 L94.8377493,26.8448315 C92.3272619,25.0652303 90.6665945,24.4522805 88.5230994,24.4522805 C85.1400579,24.4522805 83.0712604,26.1941783 82.8839752,29.1673619 L95.1397873,29.1673619 C95.394192,35.373344 91.2998999,38.8894568 86.3341362,38.8894568" id="path57" fill="#FFFFFF" fillRule="nonzero"></path>
                <polygon id="path59" fill="#FFFFFF" fillRule="nonzero" points="97.2129151 21.5782031 102.571869 21.5782031 102.571869 38.5759798 97.2129151 38.5759798"></polygon>
                <path d="M117.4841,26.224341 C116.367967,25.2246619 115.440201,24.8239285 114.295921,24.8239285 C111.772443,24.8239285 110.175648,26.8900756 110.175648,29.9871419 C110.175648,33.3804492 111.772443,35.3367179 114.473463,35.3367179 C115.440201,35.3367179 116.702482,34.893972 117.4841,34.3391932 L117.4841,26.224341 Z M117.4841,46.686523 L117.4841,37.8305295 C116.036699,38.527504 114.593629,38.894843 113.033641,38.894843 C108.145823,38.894843 104.763864,35.3000917 104.763864,30.0980976 C104.763864,25.0081366 108.145823,21.2647261 112.731603,21.2647261 C114.550326,21.2647261 115.955506,21.7387119 117.4841,22.9516846 L117.4841,21.5835893 L122.841755,21.5835893 L122.841755,46.686523 L117.4841,46.686523" id="path61" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M130.773769,31.9821912 C130.996779,34.3413477 132.366235,35.7051341 134.47184,35.7051341 C136.548215,35.7051341 137.967469,34.302567 138.189396,31.9821912 L130.773769,31.9821912 Z M134.10593,38.8894568 C128.843541,38.8894568 125.10325,35.1902132 125.10325,29.9849874 C125.10325,24.740981 128.999432,21.2690351 134.99797,21.2690351 C136.326288,21.2690351 139.550191,21.2690351 142.622534,23.5258538 L142.622534,26.8448315 C140.108799,25.0652303 138.444883,24.4522805 136.291645,24.4522805 C132.927007,24.4522805 130.849549,26.1941783 130.662264,29.1673619 L142.912663,29.1673619 C143.167068,35.373344 139.069528,38.8894568 134.10593,38.8894568" id="path63" fill="#FFFFFF" fillRule="nonzero"></path>
                <g id="g67-Clipped">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="path71"></g>
                    <g id="g67" mask="url(#mask-2)" fill="#FFFFFF" fillRule="nonzero">
                        <g transform="translate(96.831650, 21.600825)">
                            <path d="M54.1028815,12.1081824 L54.1819094,12.1081824 C55.7722095,15.64584 57.5530426,17.3047903 59.619675,17.3047903 C60.6903399,17.3047903 61.6137749,16.8986706 63.1683502,15.7546413 L61.6938854,11.0417144 C60.2865401,11.9250516 59.3620225,12.294545 58.5122025,12.294545 C56.5462494,12.294545 55.2882993,10.4503094 54.1028815,7.33277561 L54.1028815,0 L48.7376484,0 L48.7376484,16.9934678 L54.1028815,16.9934678 L54.1028815,12.1081824" id="path73"></path>
                            <path d="M5.99115999,24.1258767 C6.49455656,22.8051799 5.58844273,20.9630988 3.97432597,20.0151272 C2.35371378,19.0682329 0.641082853,19.3687829 0.142016572,20.6894797 C-0.366792868,22.012331 0.534990672,23.8565666 2.15343772,24.799152 C3.76971962,25.7514325 5.48343312,25.4498052 5.99115999,24.1258767" id="path75"></path>
                        </g>
                    </g>
                </g>
                <path d="M55.5327614,32.3969287 C55.5327614,37.0032088 52.3868034,38.8894568 49.2819833,38.8894568 C47.1709654,38.8894568 45.4356004,38.0028879 43.8831903,36.0476965 L43.8095754,36.0476965 L43.8095754,46.686523 L38.4454248,46.686523 L38.4454248,21.6277561 L43.8095754,21.6277561 L43.8095754,32.3969287 C45.0642778,34.2034609 46.1360253,34.9758423 47.3928929,34.9758423 C49.0611383,34.9758423 50.1696934,33.7951868 50.1696934,31.2561311 L50.1696934,25.2849874 C51.9689301,26.2049507 53.7973964,26.7694247 55.5327614,26.9794866 L55.5327614,32.3969287" id="path77" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M68.9869821,38.894843 C66.8023492,38.894843 65.1341038,38.0869127 63.4268857,36.1435709 L63.4268857,38.5759798 L58.0649003,38.5759798 L58.0649003,27.0247307 C59.922596,26.8405226 62.2923489,25.9927344 63.4268857,24.5923218 L63.4268857,32.5305065 C64.8363961,34.6354343 65.8366938,35.3367179 67.1704241,35.3367179 C68.6892744,35.3367179 69.8432975,34.3391932 69.8432975,31.9423333 L69.8432975,21.6223699 L75.2063655,21.6223699 L75.2063655,32.3840018 C75.2063655,37.3899381 71.65336,38.894843 68.9869821,38.894843" id="path79" fill="#FFFFFF" fillRule="nonzero"></path>
                <polyline id="path81" fill="#FFFFFF" fillRule="nonzero" points="78.168286 16.7413935 71.9369942 16.7413935 71.9369942 12.3354802 77.9485236 12.3354802 77.9485236 10.4449232 71.9369942 10.4449232 71.9369942 5.90004584 78.3544886 5.90004584 78.3544886 4.01164336 69.8476278 4.01164336 69.8476278 18.6276415 78.168286 18.6276415 78.168286 16.7413935"></polyline>
                <polygon id="path83" fill="#FFFFFF" fillRule="nonzero" points="83.257463 4.00302544 85.1661479 4.00302544 85.1661479 18.6319505 83.257463 18.6319505"></polygon>
                <path d="M94.6158218,12.1965162 C95.9690384,12.1965162 96.8080327,11.2690121 96.8708219,9.81150584 L92.0100679,9.81150584 C92.2731332,11.3336466 93.1532653,12.1965162 94.6158218,12.1965162 Z M91.9754256,8.54359386 L98.7750683,8.54359386 C98.7750683,12.1103369 97.137135,13.8317671 94.5725189,13.8317671 C91.9115538,13.8317671 90.0636012,11.8744213 90.0636012,8.9550997 C90.0636012,6.28677515 91.7329292,3.82312629 94.7381526,3.82312629 C96.376086,3.82312629 97.4348426,4.18507907 98.6072695,4.95961494 L98.6072695,6.80815952 C97.4748978,6.07024983 96.376086,5.64473986 95.2198977,5.64473986 C93.3708625,5.64473986 92.2731332,6.63149209 91.9754256,8.54359386" id="path85" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M111.238734,11.1171212 C110.141005,11.8647261 109.408103,12.0844832 108.572356,12.0844832 C106.825083,12.0844832 105.614766,10.7508595 105.614766,8.7978226 C105.614766,6.80815952 106.903028,5.60919092 108.891716,5.60919092 C109.710141,5.60919092 110.489594,5.82033005 111.452001,6.24907174 L111.452001,4.37575063 C110.808953,4.07412331 109.58348,3.82312629 108.572356,3.82312629 C105.681886,3.82312629 103.675878,5.80093972 103.675878,8.62761861 C103.675878,11.8173275 105.508674,13.8317671 108.441365,13.8317671 C109.561828,13.8317671 110.315299,13.5721522 111.238734,13.168187 L111.238734,11.1171212" id="path87" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M119.935046,13.647559 L122.998728,13.647559 L122.998728,11.935824 L119.935046,11.935824 L119.935046,6.98913592 C119.935046,5.85695622 120.758884,5.52947513 121.315327,5.52947513 C122.006008,5.52947513 122.718341,5.76969975 123.491299,6.24476278 L123.491299,4.46300711 C122.821186,4.09566812 121.792741,3.82312629 121.162684,3.82312629 C119.084143,3.82312629 118.032964,5.10719688 118.032964,6.84586294 L118.032964,11.935824 L116.349563,11.935824 L116.349563,12.1480403 L119.935046,15.6393766 L119.935046,13.647559" id="path89" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M130.297437,13.647559 L130.297437,11.4165941 L130.33641,11.4165941 C131.309643,13.1240202 132.235243,13.8317671 133.135944,13.8317671 C133.913232,13.8317671 134.640721,13.4687371 135.440743,12.715746 L134.429619,11.0417144 C133.848277,11.6137291 133.096972,12.0079991 132.643373,12.0079991 C131.329129,12.0079991 130.297437,10.6786844 130.297437,9.03158377 L130.297437,4.00302544 L128.391025,4.00302544 L128.391025,13.647559 L130.297437,13.647559" id="path91" fill="#FFFFFF" fillRule="nonzero"></path>
                <path d="M154.999594,11.1171212 C153.896452,11.8647261 153.164632,12.0844832 152.323473,12.0844832 C150.58053,12.0844832 149.379956,10.7508595 149.379956,8.7978226 C149.379956,6.80815952 150.669301,5.60919092 152.64608,5.60919092 C153.465588,5.60919092 154.24071,5.82033005 155.216109,6.24907174 L155.216109,4.37575063 C154.558987,4.07412331 153.332431,3.82312629 152.323473,3.82312629 C149.437333,3.82312629 147.422664,5.80093972 147.422664,8.62761861 C147.422664,11.8173275 149.264121,13.8317671 152.196812,13.8317671 C153.31944,13.8317671 154.065333,13.5721522 154.999594,13.168187 L154.999594,11.1171212" id="path93" fill="#FFFFFF" fillRule="nonzero"></path>
                <polygon id="path95" fill="#FFFFFF" fillRule="nonzero" points="140.50069 4.00302544 142.40905 4.00302544 142.40905 13.6472358 140.50069 13.6472358"></polygon>
                <g id="g97" transform="translate(39.274612, 0.000437)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="g99">
                        <path d="M103.198527,17.1115277 C103.38473,16.6461599 103.058875,15.987966 102.485111,15.6529442 C101.907017,15.3168452 101.295363,15.4245692 101.121069,15.8910143 C100.946775,16.3617684 101.262886,17.0145761 101.843145,17.3506751 C102.415827,17.6910831 103.024233,17.5822818 103.198527,17.1115277" id="path105"></path>
                        <path d="M17.7650917,16.3316057 C17.4284114,16.3316057 17.2865943,16.2766664 17.1491075,16.1236982 C17.0949789,16.06445 17.0733274,16.0041246 17.0538411,15.8834736 L15.3422927,9.42434001 C14.9406581,7.15890338 12.1443713,4.89669848 8.49934706,4.89669848 L3.43182155,4.89669848 L4.31087105,8.21567625 L7.56616889,8.21567625 C7.89851889,8.21567625 8.15833647,8.33848166 8.3759337,8.57978351 C8.45604412,8.67350343 8.55997116,8.82000813 8.57404461,8.97082179 L9.92509606,14.6144844 C10.323483,16.8809983 12.7300434,19.384505 16.3739851,19.384505 L21.5638414,19.384505 L20.9002239,16.3316057 L17.7650917,16.3316057 Z M18.9342709,12.3953691 L19.5134476,14.4852156 L24.7855794,14.4852156 C25.436206,17.5154929 24.9241488,20.3227814 23.0761962,22.1616309 C19.3359056,25.8834966 11.5110659,24.6188163 5.68140882,19.0688736 C4.77096469,18.2070812 4.00233766,17.2752683 3.30191275,16.3229877 L6.4760176,16.3229877 L5.73770263,14.2288323 L1.94544844,14.2288323 C1.58278639,13.5684839 1.24285838,12.9135218 0.983040795,12.2488644 L5.48005019,12.2488644 L4.76013896,10.154709 L0.341074842,10.154709 C-0.414561305,6.95530494 0.0671838034,3.97134895 1.96818248,2.07648303 C5.6619225,-1.59798408 13.4629455,-0.306372802 19.2893549,5.24249266 C20.3091389,6.20770008 21.1513809,7.24939158 21.91243,8.31909133 L17.8343764,8.31909133 L18.4557735,10.4089378 L23.2234262,10.4089378 C23.5730974,11.0735951 23.8946216,11.7307118 24.1457786,12.3953691 L18.9342709,12.3953691" id="path107"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
    </SvgIcon>
  );
}
