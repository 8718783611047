import { motion, Transition } from "framer-motion";
import React from "react";
import { useMeasure } from "react-use";
import { Box } from "../../core/box";

const defaultContainerWidth = 1200;

const devicesTransition: Transition = {
  ease: "easeOut",
  duration: 0.01,
};

const MotionBox = motion(Box);

export interface GraphicProps {
  offsetTop: number;
}

export function Graphic(props: GraphicProps) {
  const [ref, { width: currentContainerWidth }] = useMeasure<HTMLDivElement>();
  const { offsetTop } = props;

  const imageOffset = currentContainerWidth * 0.05;

  return (
    <MotionBox
      initial="hidden"
      whileInView="visible"
      viewport={{
        amount: "all",
        once: true,
      }}
      ref={ref}
      gridArea="graphic"
      backgroundColor="background.hover"
      paddingBottom={`${(568 / defaultContainerWidth) * 100}%`}
      position="relative"
      overflow="hidden"
      // Hack to fix Safari round corners clip
      zIndex={0}
      // Make border-radius responsive
      // 1200px (max-width) / 30 (ratio to 40) => 40px (by design) / 16 (default font size) => 2.5rem
      borderRadius={`${(currentContainerWidth || defaultContainerWidth) / 30 / 16}rem`}
    >
      <MotionBox
        position="absolute"
        left="-1%"
        top={`${imageOffset}px`}
        width={`${(764 / defaultContainerWidth) * 100}%`}
        animate={{ translateY: offsetTop }}
        transition={devicesTransition}
      >
        {/* <Image /> */}
      </MotionBox>
      <MotionBox
        position="absolute"
        bottom={`${imageOffset}px`}
        right={`${imageOffset}px`}
        width={`${(330 / defaultContainerWidth) * 100}%`}
        animate={{ translateY: -offsetTop }}
        transition={devicesTransition}
      >
        {/* <Image /> */}
      </MotionBox>
    </MotionBox>
  );
}
