import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageSensors() {
  return (
    <StaticImage
      src="../../../../images/sensors.png" 
      alt="Sensores"
      draggable={false}
    />
  );
}
