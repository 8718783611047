import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageSensorsMeter() {
  return (
    <StaticImage
      src="../../../../images/sensors-meter.png" 
      alt="Sensores y variables"
      draggable={false}
    />
  );
}
