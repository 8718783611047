import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImageDatalogger() {
  return (
    <StaticImage
      src="../../../../images/datalogger.png" 
      alt="Datalogger"
      draggable={false}
    />
  );
}
