import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconArduino(props: SvgIconProps) {
  return (
    <SvgIcon width="150px" height="70px" viewBox="0 0 150 70" fill="none" {...props}>
       <g id="arduino" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="wiki-Behnam-N" transform="translate(18.000000, 11.000000)" stroke="#ffffff" strokeWidth="6">
            <g id="Group">
                <path d="M25.1345636,0.240937338 C8.77489946,-1.06390722 -4.9699708,18.3398381 1.73903972,33.3360701 C8.27602432,49.6078437 32.8757295,54.0205281 44.5734915,40.9893196 C55.909999,29.8541863 60.2794571,12.7205603 74.1619481,3.92966568 C88.2680728,-5.72308138 110.975493,3.39186977 113.727908,20.5461803 C116.411512,36.9730873 98.7444507,52.9518311 82.7632436,48.1254576 C70.9450636,45.4537151 62.4641862,35.3355677 57.6474607,24.6830719 C51.7125668,14.5132133 43.9541982,3.6538729 31.8435741,0.895945174 C29.6932502,0.406413002 27.474116,0.213358061 25.2721843,0.216805471 L25.1345636,0.240937338 Z" id="Path"></path>
                <line x1="15.5011126" y1="23.5109526" x2="36.1442218" y2="23.5109526" id="Path"></line>
                <path d="M78.8066477,23.5109526 L96.0092388,23.5109526 M87.2359173,14.8924284 L87.2359173,32.1294767" id="Shape"></path>
            </g>
        </g>
    </g>
    </SvgIcon>
  );
}
