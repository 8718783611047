import React from "react";
import { PageProps } from "gatsby";
import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

// Hero section
import { HeroSection } from "../components/rebrand/sections/Hero/HeroSection";
import { heroData } from "../data/home/hero";
// General Services
import { GeneralServicesSection } from "../components/rebrand/sections/GeneralServices/GeneralServices";
import { generalServicesData } from "../data/home/generalServices";
// SolutionsAndBussiness
import { SolutionsAndBussinessSection } from "../components/rebrand/sections/SolutionsAndBussinessSection/SolutionsAndBussinessSection";
import { solutionsAndBussinessData } from "../data/home/solutionsAndBussiness";
// ControlAndAutomation
import { ControlAndAutomationSection } from "../components/rebrand/sections/ControlAndAutomationSection/ControlAndAutomationSection";
import { controlAndAutomationData } from "../data/home/controlAndAutomation";
// PromoPOSSection
import { PromoPOSSection } from "../components/rebrand/sections/PromoPOSSection/PromoPOSSection";
import { promoPOSData } from "../data/home/promoPOS";
// PromoMeterIOTSection
import { PromoMeterIOTSection } from "../components/rebrand/sections/PromoMeterIOTSection/PromoMeterIOTSection";
import { promoMeterIOTData } from "../data/home/promoMeterIOT";
// BlogProjectsSections
import { BlogProjectsSection } from "../components/rebrand/sections/BlogProjectsSection/BlogProjectsSection";
import { blogProjectsData } from "../data/home/blogProjects";

import { initialNavigationTheme } from "../data/home";

export default function IndexPage(props: PageProps) {
  const {
    location: { pathname },
  } = props;

  return (
    <Layout initialNavigationTheme={initialNavigationTheme}>
      <SEO pathname={pathname} />
      {/* Hero section */}
      <HeroSection {...heroData} />
      {/* Servicios y soluciones */}
      {/* <GeneralServicesSection {...generalServicesData} /> */}
      {/* Soluciones y áreas de negocio*/}
      <SolutionsAndBussinessSection {...solutionsAndBussinessData} />
      {/* Control y automatización para la industria */}
      <ControlAndAutomationSection {...controlAndAutomationData} /> 
      {/* Automatización para puntos de venta (vending) */}
      <PromoPOSSection {...promoPOSData} />
      {/* Producto : Medidor IoT */}
      <PromoMeterIOTSection {...promoMeterIOTData} />
      {/* Blog Proyectos */}
      <BlogProjectsSection {...blogProjectsData} />
    </Layout>
  );
}
