import React from "react";
import type { HeroSectionProps } from "../../components/rebrand/sections/Hero/HeroSection";
import type { CustomerKey } from "../nodes/customers/customers";
import { links } from "../site";

const featuredCustomers: CustomerKey[] = [
  "blend",
  "bloomreach",
  "cerebral",
  "melio",
  "hippo",
  "miramax",
  "bitpanda",
  "hirez",
  "cameo",
  "komodo-health",
  "chargebee",
];

export const heroData: HeroSectionProps = {
  theme: "dark",
  title: "Soluciones para un mundo versátil a través de la tecnología e innovación",
  subtitle: "Desarrollamos soluciones enfocadas en maximizar la eficiencia en los procesos de tu empresa.",
  movingLogosProps: {
    speedPixelsPerSecond: 20,
    featuredCustomers: [
      ...featuredCustomers,
      ...featuredCustomers,
    ]
  },
};
