import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconMitsubishi(props: SvgIconProps) {
  return (
    <SvgIcon width="140px" height="54px" viewBox="0 0 140 54"fill="none" {...props}>
<g id="mitsubishi" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(4.000000, 8.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="g3706" transform="translate(10.877655, 31.401862) scale(-1, 1) rotate(-180.000000) translate(-10.877655, -31.401862) translate(0.000000, 25.121453)">
                <polygon id="path3708" points="14.5029281 0 0 0 7.2523823 12.5608176 21.7553104 12.5608176"></polygon>
            </g>
            <g id="g3710" transform="translate(32.633788, 31.401862) scale(-1, 1) rotate(-180.000000) translate(-32.633788, -31.401862) translate(21.755215, 25.121453)">
                <polygon id="path3712" points="7.25146404 0 21.7571469 0 14.5056829 12.5608176 0 12.5608176"></polygon>
            </g>
            <g id="g3714" transform="translate(21.754756, 12.560358) scale(-1, 1) rotate(-180.000000) translate(-21.754756, -12.560358) translate(14.502833, 0.000000)">
                <polygon id="path3716" points="14.5038463 12.5598994 7.2523823 25.120717 0 12.5598994 7.2523823 0"></polygon>
            </g>
            <g id="g3718" transform="translate(52.146354, 31.605810) scale(-1, 1) rotate(-180.000000) translate(-52.146354, -31.605810) translate(47.561963, 25.529713)">
                <polygon id="path3720" points="2.46184312 5.34700204 8.2707277 5.34700204 8.2707277 7.45348026 2.46184312 7.45348026 2.46184312 10.0447974 8.99706784 10.0447974 8.99706784 12.1521939 2.46184312 12.1521939 0 12.1521939 0 10.0447974 0 2.10647822 0 0 2.46184312 0 9.16878162 0 9.16878162 2.10647822 2.46184312 2.10647822"></polygon>
            </g>
            <g id="g3722" transform="translate(53.617211, 17.428491) scale(-1, 1) rotate(-180.000000) translate(-53.617211, -17.428491) translate(47.561316, 11.353313)">
                <polygon id="path3724" points="2.2928841 9.03196155 4.87318218 0 4.9760268 0 7.1366821 0 7.23769021 0 9.81890655 9.03196155 9.81890655 0 12.1117906 0 12.1117906 12.1503574 10.8611266 12.1503574 9.78033982 12.1503574 8.60038144 12.1503574 6.05681358 3.62159987 3.51232746 12.1503574 2.33236909 12.1503574 1.25066405 12.1503574 0 12.1503574 0 0 2.2928841 0"></polygon>
            </g>
            <polygon id="path3726" points="105.791116 11.3534109 103.34305 11.3534109 103.34305 23.5037695 105.791116 23.5037695"></polygon>
            <polygon id="path3728" points="107.786485 25.5294401 105.339335 25.5294401 105.339335 37.6825514 107.786485 37.6825514"></polygon>
            <polygon id="path3730" points="130 11.3534109 127.552868 11.3534109 127.552868 23.5037695 130 23.5037695"></polygon>
            <polygon id="path3732" points="63.0968289 11.3534109 60.6487544 11.3534109 60.6487544 23.5037695 63.0968289 23.5037695"></polygon>
            <g id="g3734" transform="translate(121.699711, 17.428766) scale(-1, 1) rotate(-180.000000) translate(-121.699711, -17.428766) translate(116.858668, 11.354047)">
                <polygon id="path3736" points="7.27350218 7.41032225 2.4085843 7.41032225 2.4085843 12.1494391 0 12.1494391 0 0 2.4085843 0 2.4085843 5.30384403 7.27350218 5.30384403 7.27350218 0 9.68208647 0 9.68208647 12.1494391 7.27350218 12.1494391"></polygon>
            </g>
            <g id="g3738" transform="translate(67.966792, 17.428678) scale(-1, 1) rotate(-180.000000) translate(-67.966792, -17.428678) translate(63.747867, 11.353500)">
                <polygon id="path3740" points="2.96872018 0 5.46913003 0 5.46913003 10.0457157 8.43785021 10.0457157 8.43785021 12.1503574 5.46913003 12.1503574 2.96872018 12.1503574 0 12.1503574 0 10.0457157 2.96872018 10.0457157"></polygon>
            </g>
            <g id="g3742" transform="translate(90.291605, 31.605716) scale(-1, 1) rotate(-180.000000) translate(-90.291605, -31.605716) translate(86.073139, 25.529160)">
                <polygon id="path3744" points="2.97055669 0 5.46729352 0 5.46729352 10.0447974 8.43693195 10.0447974 8.43693195 12.1531121 5.46729352 12.1531121 2.97055669 12.1531121 0 12.1531121 0 10.0447974 2.97055669 10.0447974"></polygon>
            </g>
            <g id="g3746" transform="translate(87.400935, 17.597914) scale(-1, 1) rotate(-180.000000) translate(-87.400935, -17.597914) translate(82.582848, 11.353777)">
                <path d="M4.78870267,0 C7.90618025,0 9.6361737,1.41870482 9.6361737,4.1312317 L9.6361737,12.4882754 L7.18902267,12.4882754 L7.18902267,3.90993211 C7.18902267,2.86587556 6.30933385,1.99261453 4.78870267,1.99261453 C2.94851856,1.99261453 2.40674779,3.02289726 2.40674779,3.90993211 L2.40674779,12.4882754 L0,12.4882754 L0,4.1312317 C0,1.21117906 1.81263645,0 4.78870267,0" id="path3748"></path>
            </g>
            <g id="g3750" transform="translate(70.995842, 31.605810) scale(-1, 1) rotate(-180.000000) translate(-70.995842, -31.605810) translate(66.411451, 25.529713)">
                <polygon id="path3752" points="2.46092486 5.34700204 8.27256421 5.34700204 8.27256421 7.45348026 2.46092486 7.45348026 2.46092486 10.0447974 8.99798609 10.0447974 8.99798609 12.1521939 2.46092486 12.1521939 0 12.1521939 0 10.0447974 0 2.10647822 0 0 2.46092486 0 9.16878162 0 9.16878162 2.10647822 2.46092486 2.10647822"></polygon>
            </g>
            <g id="g3754" transform="translate(97.925429, 17.428768) scale(-1, 1) rotate(-180.000000) translate(-97.925429, -17.428768) translate(93.083468, 11.354049)">
                <path d="M5.48382212,10.2201842 C6.15231216,10.2201842 6.89977217,9.59852522 6.89977217,8.78127778 C6.89977217,8.05310114 6.37728476,7.25146404 5.34700204,7.25146404 L2.43245894,7.25146404 L2.43245894,10.2201842 L5.48382212,10.2201842 Z M5.30292578,1.83651109 L2.43245894,1.83651109 L2.43245894,5.35985762 L5.27445985,5.35985762 C6.29739653,5.35985762 7.16239326,4.77217407 7.16239326,3.59405221 C7.16239326,2.59682668 6.37728476,1.83651109 5.30292578,1.83651109 M2.52703926,0 L6.03477545,0 C8.3203135,0 9.68392298,1.36728251 9.68392298,3.28000881 C9.68392298,4.82635115 8.56181471,6.15323041 7.34053483,6.45809125 C8.40846603,6.72989489 9.26611671,7.81986423 9.26611671,9.16143558 C9.26611671,10.9529521 7.94199221,12.1494391 5.47923084,12.1494391 L2.52703926,12.1494391 L0.0119373221,12.1494391 L0,12.1494391 L0,0 L0.0119373221,0 L2.52703926,0 Z" id="path3756"></path>
            </g>
            <g id="g3758" transform="translate(99.908492, 31.588544) scale(-1, 1) rotate(-180.000000) translate(-99.908492, -31.588544) translate(95.109689, 25.512447)">
                <path d="M2.46276137,6.73815919 L2.46276137,10.0447974 L5.42597202,10.0447974 C6.42595231,10.0447974 7.09260583,9.34325018 7.09260583,8.38367313 C7.09260583,7.70875531 6.50033101,6.73815919 5.49851421,6.73815919 L2.46276137,6.73815919 Z M7.34053483,5.81623063 C8.38275488,6.13027402 9.50210839,7.06505817 9.50210839,8.75189361 C9.50210839,10.9125489 8.03840905,12.1521939 5.54810001,12.1521939 L2.46276137,12.1521939 L0.0247928997,12.1521939 L0,12.1521939 L0,0 L2.46276137,0 L2.46276137,4.78870267 L4.55179274,4.78870267 C5.81623063,4.78870267 6.50308577,4.13306821 6.71061153,3.26715323 C6.93742065,2.31216746 6.87957055,0.888871368 7.12382652,0 L9.59760696,0 C9.35702401,0.930192868 9.21561266,2.19554901 9.16235383,3.19461104 C9.07328305,4.83920673 8.20828632,5.49484119 7.34053483,5.81623063" id="path3760"></path>
            </g>
            <g id="g3762" transform="translate(61.615034, 31.606083) scale(-1, 1) rotate(-180.000000) translate(-61.615034, -31.606083) translate(57.520533, 25.529527)">
                <polygon id="path3764" points="2.46092486 12.1531121 0 12.1531121 0 2.10739648 0 0 2.46092486 0 8.18900296 0 8.18900296 2.10739648 2.46092486 2.10739648"></polygon>
            </g>
            <g id="g3766" transform="translate(77.035668, 17.400946) scale(-1, 1) rotate(-180.000000) translate(-77.035668, -17.400946) translate(72.128969, 10.984177)">
                <path d="M4.16520715,5.44892841 C6.13762007,5.10458258 7.25881009,4.74095338 7.25881009,3.56374977 C7.25881009,2.76670396 6.46911032,2.01097964 4.83369719,2.01097964 C3.19736581,2.01097964 2.3589985,2.94117251 2.3589985,4.04491568 L0,4.04491568 C0,1.93200967 1.36911902,0 4.94021484,0 C8.33959687,0 9.81339702,1.79886261 9.81339702,3.85300027 C9.81339702,5.76756308 8.55814169,6.98241517 6.31300688,7.42317783 C4.15694286,7.84649364 2.67671492,7.93831919 2.67671492,9.30468344 C2.67671492,10.2431406 3.53528385,10.797767 4.85389881,10.797767 C6.25791154,10.797767 7.06322166,10.108157 7.06322166,8.94289076 L9.44058526,8.94289076 C9.44058526,10.8602083 7.98790499,12.8335395 4.75656373,12.8335395 C2.52795752,12.8335395 0.215790053,11.5498183 0.215790053,9.14858 C0.215790053,7.13025431 1.6978545,5.88050851 4.16520715,5.44892841" id="path3768"></path>
            </g>
            <g id="g3770" transform="translate(111.293125, 17.400946) scale(-1, 1) rotate(-180.000000) translate(-111.293125, -17.400946) translate(106.386886, 10.984177)">
                <path d="M4.16612541,5.44892841 C6.13670181,5.10458258 7.25697358,4.74095338 7.25697358,3.56374977 C7.25697358,2.76670396 6.47002857,2.01097964 4.83369719,2.01097964 C3.19828407,2.01097964 2.35716199,2.94117251 2.35716199,4.04491568 L0,4.04491568 C0,1.93200967 1.36820076,0 4.93837832,0 C8.33776035,0 9.81247876,1.79886261 9.81247876,3.85300027 C9.81247876,5.76756308 8.55814169,6.98241517 6.31392513,7.42317783 C4.1560246,7.84649364 2.67763317,7.93831919 2.67763317,9.30468344 C2.67763317,10.2431406 3.5343656,10.797767 4.85481707,10.797767 C6.25515678,10.797767 7.0623034,10.108157 7.0623034,8.94289076 L9.44058526,8.94289076 C9.44058526,10.8602083 7.98698674,12.8335395 4.75656373,12.8335395 C2.52612101,12.8335395 0.213953542,11.5498183 0.213953542,9.14858 C0.213953542,7.13025431 1.69877276,5.88050851 4.16612541,5.44892841" id="path3772"></path>
            </g>
            <g id="g3774" transform="translate(80.871774, 31.589095) scale(-1, 1) rotate(-180.000000) translate(-80.871774, -31.589095) translate(75.452689, 25.235685)">
                <path d="M5.66655497,2.13769891 C3.68679602,2.13769891 2.53163054,4.07705462 2.53163054,6.38646732 C2.53163054,8.69679827 3.64639277,10.6389088 5.66655497,10.6389088 C7.16882104,10.6389088 7.9401557,9.70320635 8.27440072,8.56089645 L10.8234781,8.56089645 C10.4056718,10.9391783 8.54528611,12.7068202 5.59676755,12.7068202 C1.8796691,12.7068202 0,9.86206456 0,6.35249186 C0,2.84291917 1.95680257,0 5.59676755,0 C8.5103924,0 10.4772958,1.69326323 10.8381702,4.28917165 L8.27440072,4.28917165 C7.9401557,3.06789178 7.14219163,2.13769891 5.66655497,2.13769891" id="path3776"></path>
            </g>
            <g id="g3778" transform="translate(113.800695, 31.589095) scale(-1, 1) rotate(-180.000000) translate(-113.800695, -31.589095) translate(108.381151, 25.235685)">
                <path d="M5.66747323,2.13769891 C3.68863253,2.13769891 2.53346705,4.07705462 2.53346705,6.38646732 C2.53346705,8.69679827 3.64639277,10.6389088 5.66747323,10.6389088 C7.17157581,10.6389088 7.94291047,9.70320635 8.27531898,8.56089645 L10.8243964,8.56089645 C10.4056718,10.9391783 8.54620436,12.7068202 5.59676755,12.7068202 C1.87875085,12.7068202 0,9.86206456 0,6.35249186 C0,2.84291917 1.95680257,0 5.59676755,0 C8.51131065,0 10.4772958,1.69326323 10.8390885,4.28917165 L8.27531898,4.28917165 C7.94291047,3.06789178 7.14127338,2.13769891 5.66747323,2.13769891" id="path3780"></path>
            </g>
        </g>
    </g>
    </SvgIcon>
  );
}
