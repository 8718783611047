import React from "react";
import styled, { ThemeProvider } from "styled-components";
// import { CustomerKey } from "../../../../data/nodes/customers/customers/";
import { getTheme } from "../../../../theme";
import { AppLink } from "../../../../types";
import { Box } from "../../core/box";
import { LinkStyleButton } from "../../core/LinkStyleButton/LinkStyleButton";
import { SmartButton } from "../../core/SmartButton/SmartButton";
import { Typography } from "../../core/Typography/Typography";
import { getCustomerLogo } from "../../utils/getCustomerLogo";
import { MarqueeRowSection } from "../MarqueeRow/MarqueeRowSection";
import { PageHeaderSectionProps } from "../PageHeader/PageHeaderSection";
import { ThreeLazy } from "./DotGrid/Three-Lazy";

const GridHeight = 1000;

const AnnouncementLinkButton = styled(LinkStyleButton)`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.text.muted};
  color: ${({ theme }) => theme.colors.text.muted};
  border-radius: ${({ theme }) => theme.radii[5]};
  line-height: ${({ theme }) => theme.lineHeights[4]};
  padding-left: 1rem;
  padding-right: 1rem;
  pointer-events: auto;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: ${({ theme }) => theme.colors.text.loud};
    border-color: ${({ theme }) => theme.colors.text.loud};
  }
`;

const LogoContainer = styled(Box)`
  svg {
    width: auto;
  }
`;

export interface HeroSectionProps extends PageHeaderSectionProps {
  announcement?: Omit<AppLink, "iconKey">;
  movingLogosProps: {
    featuredCustomers: [];
    speedPixelsPerSecond: number;
  };
}

export function HeroSection(props: HeroSectionProps) {
  const { theme: themeVariant, announcement, title, subtitle, cta, movingLogosProps } = props;

  const theme = getTheme(themeVariant);
  const { featuredCustomers, speedPixelsPerSecond } = movingLogosProps;

  return (
    <ThemeProvider theme={theme}>
      <Box
        as="section"
        data-section-theme={themeVariant}
        width="100%"
        backgroundColor="background.base"
        minHeight={["0", "62.5rem"]}
        backgroundImage='linear-gradient(rgba(0,0,0,.74), rgba(0,0,0,.44)), url("images/raeng.jpg")'
        backgroundSize='cover'
      >
        <Box position="relative" zIndex={1} pointerEvents="none">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={["auto", 760]}
            paddingTopSpacing={10}
            boxSizing="border-box"
          >
            {announcement && announcement.label && (
              <Box
                zIndex={1}
                paddingTopSpacing={2}
                paddingXSpacing={2.5}
                marginTopSpacing={[2, -2.5]}
                marginBottomSpacing={-0.5}
                backgroundColor="background.base"
              >
                <AnnouncementLinkButton {...announcement} />
              </Box>
            )}
            <Box 
              textAlign="center" 
              width={["100%", 900]} 
              paddingY={5} 
              paddingX={5} 
              // backgroundColor="background.base"
            >
              <Typography variant="h1" fontSize={[6, 8, 9]}>
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  variant="h2"
                  fontWeight={1}
                  fontSize={[3, 4, 5]}
                  lineHeight={3}
                  marginTopSpacing={[2.5, 3]}
                  // color="text.muted"
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
            {cta && (
              <Box
                paddingY={5}
                paddingX={5}
                backgroundColor="background.base"
                display="inline-flex"
                width={["100%", "auto"]}
                flexDirection={["column", "row"]}
                justifyContent="center"
                pointerEvents="auto"
              >
                <SmartButton variant="primary" size="large" marginBottomSpacing={[2, 0]} {...cta.primary}>
                  {cta.primary.label}
                </SmartButton>
                {cta.secondary && (
                  <StyledSecondarySmartButton size="large" marginLeftSpacing={[0, 2.5]} {...cta.secondary}>
                    {cta.secondary.label}
                  </StyledSecondarySmartButton>
                )}
              </Box>
            )}
          </Box>
          {/* <MarqueeRowSection
            theme={themeVariant}
            speed={speedPixelsPerSecond}
            withFadeEffect={true}
            paddingTopSpacing={[6, 6]}
            paddingBottomSpacing={[6, 6]}
            minHeight={["6rem", "6rem", "2rem"]}
          >
            {featuredCustomers.map((key, idx) => (
              <LogoContainer key={`${key}-${idx}`} color="text.muted" height="2rem" marginXSpacing={[2.125, 4.5]}>
                {getCustomerLogo(key)}
              </LogoContainer>
            ))}
          </MarqueeRowSection> */}
        </Box>
        <Box display={["none", "block"]} position="absolute" width="100%" top={0}>
          <Box
            position="absolute"
            zIndex={1}
            width={1100}
            height={20}
            left="50%"
            marginLeft={-1100 / 2}
            // backgroundColor="background.base"
          ></Box>
          {/* <ThreeLazy height={GridHeight} /> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const StyledSecondarySmartButton = styled(SmartButton).attrs({
  variant: "secondary",
})`
  border-color: ${({ theme }) => theme.colors.button.primary.inactive.borderColor};

  &:hover {
    color: ${({ theme }) => theme.colors.button.primary.inactive.color};
    background-color: ${({ theme }) => theme.colors.button.primary.inactive.backgroundColor};
  }
`;
