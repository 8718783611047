import React from "react";
import { Box } from "../../core/box";
import { BrowserSubSection, BrowserSubSectionProps } from "./BrowserSubSection/BrowserSubSection";
import { DeploymentSubSection, DeploymentSubSectionProps } from "./DeploymentSubSection/DeploymentSubSection";

export interface ControlAndAutomationSectionProps {
  browserSection: BrowserSubSectionProps;
  deploymentSection: DeploymentSubSectionProps;
}

export function ControlAndAutomationSection(props: ControlAndAutomationSectionProps) {
  const { browserSection, deploymentSection } = props;

  return (
    <Box position="relative">
      {/* <BrowserSubSection zIndex={1} {...browserSection} paddingBottomSpacing={[0, 0, 0, 0 ]} /> */}
      <DeploymentSubSection {...deploymentSection} />
    </Box>
  );
}
