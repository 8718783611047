import React from "react";

// Logos proveedores
import { IconSchneider } from "../../components/rebrand/icons/brandProviders/IconSchneider";
import { IconMitsubishi } from "../../components/rebrand/icons/brandProviders/IconMitsubishi";
import { IconPhoenixContact } from "../../components/rebrand/icons/brandProviders/IconPhoenixContact";
import { IconArduino } from "../../components/rebrand/icons/brandProviders/IconArduino";
import { IconRaspberry } from "../../components/rebrand/icons/brandProviders/IconRaspberry";
import { IconSiemens } from "../../components/rebrand/icons/brandProviders/IconSiemens";

import { IconBeagleboard } from "../../components/rebrand/icons/brandProviders/IconBeagleboard";
import { IconElo } from "../../components/rebrand/icons/brandProviders/IconElo";
import { IconEndress } from "../../components/rebrand/icons/brandProviders/IconEndress";
import { IconAdafruit } from "../../components/rebrand/icons/brandProviders/IconAdafruit";
import { IconWago } from "../../components/rebrand/icons/brandProviders/IconWago";

// Imagenes por servicio
import { ScadaService } from "../../components/rebrand/graphics/services/ScadaService";
import { ImageScada } from "../../components/rebrand/graphics/home/ImageScada";
import { ImageSecTelemetry } from "../../components/rebrand/graphics/home/ImageSecTelemetry";
import { ImageSensors } from "../../components/rebrand/graphics/home/ImageSensors";
import { ImageActuators } from "../../components/rebrand/graphics/home/ImageActuators";
import { ImageDatalogger } from "../../components/rebrand/graphics/home/ImageDatalogger";

import type { ControlAndAutomationSectionProps } from "../../components/rebrand/sections/ControlAndAutomationSection/ControlAndAutomationSection";
import { IconSmartRouting } from "../../components/rebrand/icons/IconSmartRouting";

const brandProviders = {
  arduino: {
    Icon: <IconArduino />,
    tooltip: "Arduino",
  },
  raspberry: {
    Icon: <IconRaspberry />,
    tooltip: "Raspberry",
  },
  schneider: {
    Icon: <IconSchneider />,
    tooltip: "Schneider Electric",
  },
  mitsubishi: {
    Icon: <IconMitsubishi />,
    tooltip: "Mitsubishi",
  },
  siemens: {
    Icon: <IconSiemens />,
    tooltip: "Siemens",
  },
  phoenixContact: {
    Icon: <IconPhoenixContact />,
    tooltip: "Phoenix Contact",
  },
  // 2
  wago: {
    Icon: <IconWago />,
    tooltip: "Wago",
  },
  adafruit: {
    Icon: <IconAdafruit />,
    tooltip: "Adafruit",
  },
  endress: {
    Icon: <IconEndress />,
    tooltip: "Endress",
  },
  beagleboard: {
    Icon: <IconBeagleboard />,
    tooltip: "Beagleboard",
  },
  elo: {
    Icon: <IconElo />,
    tooltip: "Elo Touch",
  },
};

const servicesElements = {
  scada: {
    name: "SCADA",
    cliCommand: <ScadaService />,
    textBody: "Diseño y desarrollo de software SCADA a medida según la interfaz que se implemente.",
    imageBody: <ImageScada />
  },
  telemetry: {
    name: "Telemetría",
    cliCommand: <ScadaService />,
    textBody: "Comunica tus unidades remotas (RTU) mediante radio a un concetrador que enviará todos tus datos a nuestra nube para poder servirlos y visualizarlos.",
    imageBody: <ImageSecTelemetry />
  },
  sensors: {
    name: "Sensores",
    cliCommand: <ScadaService />,
    textBody: "Distruye distintos tipos de sensores, midiendo variables físicas y obteniendo salidas que posteriormente serán procesadas por un controlador, generando indicadores, registrando eventos o realizando acciones según lectura.",
    imageBody: <ImageSensors />
  },
  actuators: {
    name: "Actuadores",
    cliCommand: <ScadaService />,
    textBody: "Controla procesos industriales y de fabricación mediante el accionamiento de cualquier actuador distribuído y comandado por un controlador. Donde la entrada de ejecución puede ser una lectua de un sensor o un accionamiento manual.",
    imageBody: <ImageActuators />
  },
  datalogger: {
    name: "Datalogger",
    cliCommand: <ScadaService />,
    textBody: "Registra todos los eventos y lecturas obtenidas por tus sensores almacenando todos tus datos en una memoria local.",
    imageBody: <ImageDatalogger />
  },
  notifications: {
    name: "Notificaciones",
    cliCommand: <ScadaService />,
    textBody: "notifications contenido"
  },
};

export const controlAndAutomationData: ControlAndAutomationSectionProps = {
  browserSection: {
    captionProps: {
      text: "Pre Titulo",
      Icon: <IconSmartRouting />,
      accent: "text.red",
    },
    theme: "light",
    title: "Titulo",
    subtitle:
      "Subtitulo",
    graphicProps: {
      name: "it-admin-graphic",
      animationData: '',
      config: {
        loop: true,
        threshold: [0.1, 0.8],
      },
    },
  },
  deploymentSection: {
    theme: "dark",
    gridTemplate: [
      // 1rem = 16px
      // mobile
      `
        "title"
        "." 1.5rem
        "text"
        "." 1.5rem
        "connector"
        "." 1.25rem
        "getStarted"
        "." 3.75rem
        "identityProviders"
        / 100%
      `,
      // tablet
      `
        "title"
        "." 1.5rem
        "text"
        "." 1.5rem
        "code"
        "." 1.25rem
        "connector"
        "." 1.25rem
        "getStarted"
        "." 3.75rem
        "identityProviders"
        / 100%
      `,
      // desktop
      `
        "title title title"
        ". . ." 2rem
        "formattedText formattedText formattedText"
        ". . ." 5rem
        "code . connector"
        ". . ." 1.5rem
        "getStarted getStarted getStarted"
        ". . ." 7rem
        "identityProviders identityProviders identityProviders"
        / minmax(50%, 3fr) 1.5rem 1fr
      `,
    ],
    headerSectionProps: {
      gridArea: "title",
      title: "Control, analítica y tecnología cognitiva para la industria 4.0",
      captionProps: {
        text: "Desarrollo de proyectos industriales",
      },
    },
    subtitleQuoteProps : {
      gridColumn: "1 / span 12",
      gridRow: "3",
      text: "Implementa soluciones para controlar procesos como plantas de tratamiento de aguas, procesos de fertilización para la agronomía, control de bandas transportadoras de materiales, cintas de embotellado y mucho más, todo con la ayuda de controladores y sensores.",
      color: "text.disabled",
      highlights: [
        {
          keyword: "controlar procesos",
          accent: "text.purple",
        },
        {
          keyword: "plantas de tratamiento de aguas",
          accent: "text.blue",
        },
        {
          keyword: "fertilización para la agronomía",
          accent: "text.teal",
        },
        {
          keyword: "cintas de embotellado",
          accent: "text.loud",
        },
    ],
    },
    servicesElementsCardProps: {
      gridArea: "code",
      display: ["none", "block"],
      servicesElementsMethods: [
        servicesElements.scada,
        servicesElements.telemetry,
        servicesElements.sensors,
        servicesElements.actuators,
        servicesElements.datalogger,
        // servicesElements.notifications,
      ],
    },
    connectorCardProps: {
      gridArea: "connector",
      text: "Tecnología para lograr eficiencia en los procesos industriales, reducción de costos y análitica industrial. ",
      highlights: [
        {
          keyword: "eficiencia en los procesos industriales",
          accent: "text.loud",
        },
      ]
    },
    brandProvidersProps: {
      gridArea: "identityProviders",
      title: "Controladores y Hardware utilizado en nuestras soluciones",
      items: [
        brandProviders.arduino,
        brandProviders.raspberry,
        brandProviders.schneider,
        brandProviders.mitsubishi,
        brandProviders.siemens,
        brandProviders.phoenixContact,
      ],
      items2: [
        brandProviders.wago,
        brandProviders.endress,
        brandProviders.adafruit,
        brandProviders.beagleboard,
        brandProviders.elo,
      ],
    },
  },
};
