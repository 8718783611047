import React from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ElementType, ForwardedRef, forwardRef, ReactElement } from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import { Box } from "../../core/box";

const sizes = variant({
  prop: "size",
  variants: {
    small: {
      fontSize: 0,
      fontWeight: 2,
      minHeight: "3.25rem",
      paddingX: "0.5rem",
    },
    large: {
      fontFamily: 1,
      fontSize: [1, 2, 2, 4],
      fontWeight: 1,
      lineHeight: 1,
      justifyContent: "start",
      minHeight: ["4.5rem", "4.5rem", "4.5rem", "5.5rem"],
      paddingX: ["1rem", "1rem", "1rem", "1.5rem"],
    },
  },
});

const sharedActiveStyle = css`
  color: ${({ theme }) => theme.colors.blue._400};
  background-color: ${({ theme }) => theme.colors.background.base};
  border-color: ${({ theme }) => theme.colors.blue._500};
`;

interface ComponentProps {
  isActive?: boolean;
}

const sharedStyle = css<ComponentProps>`
  outline: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.loud};
  border-radius: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.border.default};
  background-color: ${({ theme }) => theme.colors.background.faint};
  transition: unset;

  ${sizes}

  &:focus,
  &:hover {
    ${sharedActiveStyle}
  }

  ${(props) => {
    const { isActive } = props;
    if (!isActive) return;
    return sharedActiveStyle;
  }}
`;

const Button = styled("button")`
  ${sharedStyle}
`;
const Link = styled(GatsbyLink)`
  ${sharedStyle}
`;

const ExternalLink = styled("a").attrs(({ to }: LinkProps) => ({ href: to }))`
  ${sharedStyle}
`;

type ButtonType = "submit" | "reset" | "button";
type ButtonProps<T extends ButtonType> = { type: T; target: never } & ButtonHTMLAttributes<T>;
type LinkProps = { type?: never; target?: AnchorHTMLAttributes<HTMLLinkElement>["target"] } & Omit<
  GatsbyLinkProps<Record<string, unknown>>,
  "type"
>;

export type DeploymentButtonProps<T> = ComponentProps & {
  icon?: ReactElement;
  size?: "small" | "large";
} & (T extends ButtonType ? ButtonProps<T> : LinkProps);

function InnerDeploymentButton<T>(props: DeploymentButtonProps<T>, ref: ForwardedRef<HTMLElement>) {
  const { size = "small", icon, isActive, children, ...otherProps } = props;
  const Component: ElementType = otherProps.type ? Button : otherProps.target === "_blank" ? ExternalLink : Link;

  return (
    <Component size={size} ref={ref} isActive={isActive} {...otherProps}>
      {icon && (
        <Box flexShrink={0} width="2.5rem" height="2.5rem" marginRightSpacing={2.5}>
          {icon}
        </Box>
      )}{" "}
      <Box flexGrow={1} textAlign="center">
        {children}
      </Box>
    </Component>
  );
}

export const DeploymentButton = forwardRef(InnerDeploymentButton) as <T>(
  props: DeploymentButtonProps<T> & { ref?: ForwardedRef<HTMLElement> },
) => ReturnType<typeof InnerDeploymentButton>;
