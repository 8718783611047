import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function ImagePayments() {
  return (
    <StaticImage
      src="../../../../images/pagos-automaticos.png" 
      alt="Pagos"
      draggable={false}
    />
  );
}
