import React from "react";
// import TwoFactorAnimation from "../../../content/lottie/2-fa.json";
// import DeviceIdentityAnimation from "../../../content/lottie/Device-identity.json";
// import DeviceScannerAnimation from "../../../content/lottie/DeviceScanner.json";
// import LoggingAnimation from "../../../content/lottie/logging.json";
import { IconAnalytics } from "../../components/rebrand/icons/security/IconAnalytics";
import { IconPhoneLock } from "../../components/rebrand/icons/security/IconPhoneLock";
import { IconVerified } from "../../components/rebrand/icons/security/IconVerified";

import { IconScreens } from "../../components/rebrand/icons/pos/IconScreens";

import type { PromoPOSSectionProps } from "../../components/rebrand/sections/PromoPOSSection/PromoPOSSection";


// Logos proveedores
import { IconZebra } from "../../components/rebrand/icons/partner/IconZebra";
import { IconStar } from "../../components/rebrand/icons/partner/IconStar";
import { IconCisco } from "../../components/rebrand/icons/partner/IconCisco";

// images 
import { ImagePayments } from "../../components/rebrand/graphics/home/ImagePayments";
import { ImageScreens } from "../../components/rebrand/graphics/home/ImageScreens";
import { ImageIntegration } from "../../components/rebrand/graphics/home/ImageIntegration";
import { ImageTelemetry } from "../../components/rebrand/graphics/home/ImageTelemetry";
import { IconPayments } from "../../components/rebrand/icons/pos/IconPayments";
import { IconTelemetry } from "../../components/rebrand/icons/pos/IconTelemetry";
import { IconIntegration } from "../../components/rebrand/icons/pos/IconIntegration";

const brandPartner = {
  zebra: {
    Icon: <IconZebra />,
    tooltip: "Zebra Partner Connect",
  },
  cisco: {
    Icon: <IconCisco />,
    tooltip: "Cisco Partner",
  },
  star: {
    Icon: <IconStar />,
    tooltip: "Star Partner",
  },
};


export const promoPOSData: PromoPOSSectionProps = {
  theme: "light",
  headerProps: {
    /* grid props */
    gridColumn: ["1 / span 12", "1 / span 12", "1 / span 8"],
    gridRow: "1",

    /* content props */
    variant: "medium",
    captionProps: {
      text: "Soluciones y automatización para el Retail",
    },
    title: "¿Como automatizar tus puntos de venta?",
  },
  subtitleQuoteProps: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: "2",

    /* content props */
    text: "Implementación de IoT para operar de forma remota tus puntos de venta, administrar tus ventas y conocer tus clientes.",
    color: "text.disabled",
    highlights: [
      {
        keyword: "IoT",
        accent: "text.purple",
      },
      {
        keyword: "operar de forma remota",
        accent: "text.blue",
      },
      {
        keyword: "puntos de venta",
        accent: "text.teal",
      },
      {
        keyword: "administrar tus ventas",
        accent: "text.loud",
      },
    ],
  },
  networkRequestAnimationProps: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: "3",

    /* content props */
    name: "device-identity",
    animationData: 'DeviceIdentityAnimation',
    config: {
      loop: false,
    },
  },
  networkRequestQuoteProps: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: "4",

    /* content props */
    text: "Desarrollo de placas electronicas con conectividad 3G para automatizar procesos de dispensación o entrega de productos en máquinas vending, mediante sensores y actuadores.",
  },
  cards: [
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: "5",

      /* content props */
      caption: {
        text: "Intefaces touch",
        Icon: <IconScreens color="icon.blue" />,
      },
      title: "Pantallas táctiles enfocadas en el cliente.",
      subtitle: "Desarrollo de interfaces para acompañar el proceso de ventas, diseño e integración de software y hardware de acuerdo a tus requerimientos."
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / span 6"],
      gridRow: ["6", "5"],

      /* content props */
      backgroundColor: "background.blue",
      animation: {
        name: "two-factor",
        animationData: 'TwoFactorAnimation',
        config: {
          loop: false,
        },
      },
      imageSvg: <ImageScreens />
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / span 6"],
      gridRow: ["7", "6"],

      /* content props */
      caption: {
        text: "Pagos automátizados",
        Icon: <IconPayments color="icon.blue" />,
      },
      title: "Metodos de pagos autoservicio con tarjetas, Apps y QR.",
      subtitle : "Incorpora medios de pagos físicos o electronicos mediante el uso de Apps de terceros, todo completamente integrado en tu flujo de ventas."
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: ["8", "6"],

      /* content props */
      backgroundColor: "background.blue",
      paddingXSpacing: [0, 0, 0],
      animation: {
        name: "device-scanner",
        animationData: 'DeviceScannerAnimation',
        config: {
          loop: true,
        },
      },
      imageSvg: <ImagePayments />
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / span 6"],
      gridRow: ["10", "7"],

      /* content props */
      backgroundColor: "background.blue",
      paddingXSpacing: [0, 0, 0],
      animation: {
        name: "device-scanner",
        animationData: 'DeviceScannerAnimation',
        config: {
          loop: true,
        },
      },
      imageSvg: <ImageTelemetry />
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: ["9", "7"],

      /* content props */
      caption: {
        text: "Telemetría",
        Icon: <IconTelemetry color="icon.blue" />,
      },
      title: "Telemetría y Control",
      subtitle: "Aumenta la eficiencia en tu operación mediante el control a distancia de Stock, aperturas y cierres no autorizados de cada unidad, pudiendo actualizar o realizar modificaciones remotas.",
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "1 / span 6"],
      gridRow: ["12", "8"],

      /* content props */
      backgroundColor: "background.blue",
      paddingXSpacing: [0, 0, 0],
      animation: {
        name: "device-scanner",
        animationData: 'DeviceScannerAnimation',
        config: {
          loop: true,
        },
      },
      imageSvg: <ImageIntegration />
    },
    {
      /* grid props */
      gridColumn: ["1 / span 12", "7 / span 6"],
      gridRow: ["11", "8"],

      /* content props */
      caption: {
        text: "Integración",
        Icon: <IconIntegration color="icon.blue" />,
      },
      title: "Comunicación y Supervisión",
      subtitle: "Comunica cada máquina con tu ERP, B2B o genera reportes automatizados con los KPI's que te ayudarán a tomar desiciones.",
    },
 

    // {
    //   /* grid props */
    //   gridColumn: "1 / span 12",
    //   gridRow: ["11", "8"],

    //   /* content props */
    //   caption: {
    //     text: "Dashboard autoadministrable",
    //     Icon: <IconAnalytics color="icon.teal" />,
    //   },
    //   title: "Blah blah blah ...",
    //   animation: {
    //     name: "connection-insights",
    //     animationData: 'LoggingAnimation',
    //     config: {
    //       loop: false,
    //       autoplay: false,
    //       scrollBased: true,
    //     },
    //   },
    // },
  ],
  promoReseller: {
    gridArea: "identityProviders",
    title: "Somos partners y distribuidores de grandes marcas",
    items: [
      brandPartner.zebra,
      brandPartner.cisco,
      brandPartner.star
    ]
  },
  gridTemplate: [
    // 1rem = 16px
    // mobile
    `
      "title"
      "." 1.5rem
      "text"
      "." 1.5rem
      "connector"
      "." 1.25rem
      "getStarted"
      "." 3.75rem
      "identityProviders"
      / 100%
    `,
    // tablet
    `
      "title"
      "." 1.5rem
      "text"
      "." 1.5rem
      "code"
      "." 1.25rem
      "connector"
      "." 1.25rem
      "getStarted"
      "." 3.75rem
      "identityProviders"
      / 100%
    `,
    // desktop
    `
      "title title title"
      ". . ." 2rem
      "formattedText formattedText formattedText"
      ". . ." 2rem
      "code . connector"
      ". . ." 1.5rem
      "getStarted getStarted getStarted"
      ". . ." 2rem
      "identityProviders identityProviders identityProviders"
      / minmax(50%, 3fr) 1.5rem 1fr
    `,
  ],
  footerQuote: {
    /* grid props */
    gridColumn: "1 / span 12",
    gridRow: ["11", "9"],

    /* content props */
    text: "Los desarrollos de Polarycs nos han permitido escalar nuestro negocio en corto tiempo, llevando a nuestros clientes nuevos puntos de ventas y productos.",
    color: "text.disabled",
    highlights: [
      {
        keyword: "Polarycs nos han permitido escalar nuestro negocio en corto tiempo",
        accent: "text.loud",
      },
    ],
    authorName: "Nombre y Apellido",
    authorPosition: "Cliente",
  },
};
