import React from "react";
import { SvgIcon, SvgIconProps } from "../../../core/svgicon";

export function IconLinux(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        d="M29.2396 16.7C28.3996 17.26 25.7396 18.78 25.3396 19.08C24.5596 19.7 23.8396 19.66 23.0596 19.06C22.6596 18.74 19.9996 17.22 19.1596 16.68C18.1996 16.06 18.2596 15.28 19.3196 14.84C22.5996 13.46 25.8796 13.56 29.1396 14.9C30.1196 15.32 30.1596 16.1 29.2396 16.7ZM43.6796 31.26C41.8196 27.08 39.2796 23.28 35.9996 19.94C34.9855 18.8867 34.256 17.5928 33.8796 16.18C33.6796 15.52 33.5396 14.84 33.3996 14.16C32.9996 12.4 32.8196 10.6 31.9996 8.94C30.5396 5.78 27.9996 4.14 24.3196 4C20.6996 4.1 17.9996 5.62 16.4196 8.8C15.9996 9.66 15.6996 10.56 15.4996 11.48C15.1596 13 14.8596 14.58 14.4996 16.12C14.1996 17.42 13.5996 18.54 12.5796 19.54C9.35962 22.68 6.77962 26.28 4.81962 30.24C4.53962 30.82 4.25962 31.4 4.07962 32C3.69962 33.32 4.65962 34.24 6.05962 33.92C6.93962 33.74 7.81962 33.56 8.65962 33.3C9.47962 33 9.79962 33.2 9.99962 34C11.2996 38.3 14.1396 41.32 18.4796 43C26.7196 46.12 36.3396 41.68 38.4196 33.84C38.5596 33.3 38.7596 33.1 39.3596 33.3C40.2796 33.58 41.2196 33.78 42.1596 34C43.1396 34.18 43.8596 33.68 43.9996 32.72C44.0596 32.2 43.8796 31.74 43.6796 31.26Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
